
const doc_config = [
  require('../docs_md/DocsOverview.js'),
  // agents,
  // knowledge,
  // endpoint,
  require('../docs_md/DocsAPI.js'),
  // require('../docs_md/DocsTutorials.js'),
  require('../docs_md/DocsPolicies.js'),
]


export default doc_config