
import {
  bulkUpdateComponentFromSocket
} from '../actions/actions.export'

const handleUpdateRoom = (dispatch, getState) => {
  return (data) => {
    // console.log(data);

    if(data.user_id === getState().userReducer.myData.id){
      // console.log("BLOCKING SELF UPDATE");
      return;
    } 

    // split data.room into type and id
    const [type, id] = data.room.split('-');
    
    switch(type){
      case 'components':
        dispatch(bulkUpdateComponentFromSocket({
          id: id,
          events: data.events
        }));
        break;
      default:
        console.warn('Unhandled updateRoom type:', type);
    }
    
  }
}

export default handleUpdateRoom;