import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetComponentsByOrganization,
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Create a component from a copy
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestCopyComponent = packet => {
  return {
    type: 'REQUEST_COPY_COMPONENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveCopyComponentSuccess = data => ({
  type: 'RECEIVE_COPY_COMPONENT_SUCCESS',
  data
})

export const receiveCopyComponentFail = data => ({
  type: 'RECEIVE_COPY_COMPONENT_FAIL',
  data
})

export const tryToCopyComponent = packet => (dispatch, getState) => {

  dispatch(requestCopyComponent(packet));

  let headers = {
    "Content-Type": "application/json"
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }

  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/components/copy/' + packet.id + '/' + packet.version, {
      method: 'post',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveCopyComponentSuccess(json));
      dispatch(push('/workbench/' + json.scope + '/agents/' + json.id));
      dispatch(tryToGetComponentsByOrganization({org_id: json.scope}));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveCopyComponentFail({errors:e, lastSubmit: packet}));
    })
}
