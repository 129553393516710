import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';
import { push } from 'connected-react-router'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import Callout from '../../kit/components/Callout/Callout'

import Hydrate from '../../components/Hydrate/Hydrate'
import NewComponentForm from './NewComponentForm';
import NewKnowledgeForm from './NewKnowledgeForm';
import NewProjectForm from './NewProjectForm';
import NewInquiryForm from './NewInquiryForm';

import {
  setKindFilters,
  setKindSort,
  setKindView,
  tryToGetComponentsByOrganization,
  tryToGetKnowledgeByOrganization,
  tryToGetProjectsByOrganization,
  tryToGetInquiriesByOrganization,
  tryToGetMyComponents
} from '../../actions/actions.export'

class WorkbenchKindGallery extends Component {
  constructor(props){
    super(props);

    this.state = {

    }
  }

  // on mount, based on the kind - get the things by org
  componentDidMount(){
    const { dispatch, org_id, kind, componentReducer, knowledgeReducer, projectReducer, inquiryReducer } = this.props;

    switch(kind){
      case 'components':
        if(!componentReducer.cache[org_id]){
          dispatch(tryToGetComponentsByOrganization({org_id: org_id}));
        }
        break;
      case 'knowledge':
        if(!knowledgeReducer.cache[org_id]){
          dispatch(tryToGetKnowledgeByOrganization({org_id: org_id}));
        }
        break;
      case 'projects':
        if(!projectReducer.cache[org_id]){
          dispatch(tryToGetProjectsByOrganization({org_id: org_id}));
        }
        break;
    }
  }

  render(){

    const { dispatch, projectReducer, orgReducer, userReducer, knowledgeReducer, inquiryReducer, componentReducer, guiReducer, kind} = this.props;

    let membership;
    let organization;
    if(orgReducer.memberships.length > 0){
      let m = orgReducer.memberships.find(m => m.organization.id === this.props.org_id);
      membership = m.membership;
      organization = m.organization;
    }

    if(!membership) return <span/>;

    let cacheList = [];
    let reducer;
    let kindURL;
    let kindSingular;
    let kindPlural;
    let icon;
    let tryingToGet;
    let searchKind;
    let canCreateNew;
    let atCreationLimit;

    switch(this.props.kind){
      case 'projects':
        cacheList = Object.keys(projectReducer.cache).map(id => projectReducer.cache[id]);
        reducer = projectReducer;
        kindURL = 'projects';
        kindSingular = 'Project';
        kindPlural = 'Projects';
        icon = "robot";
        searchKind = 'projects';
        tryingToGet = projectReducer.tryingToGetProjectsByOrganization || projectReducer.tryingToGetMyProjects;
        canCreateNew = membership.create_projects && organization.counts.projects < organization.quotas.projects;
        atCreationLimit = organization.counts.projects >= organization.quotas.projects;
        break;
      case 'components':
        cacheList = Object.keys(componentReducer.cache).map(id => componentReducer.cache[id]);
        reducer = componentReducer;
        kindURL = 'agents';
        kindSingular = 'Agent Flow';
        kindPlural = 'Agent Flows';
        searchKind = 'agents';
        icon = "project-diagram";
        tryingToGet = componentReducer.tryingToGetComponentsByOrganization || componentReducer.tryingToGetMyComponents;
        canCreateNew = membership.create_components && organization.counts.components < organization.quotas.components;
        atCreationLimit = organization.counts.components >= organization.quotas.components;
        break;
      case 'knowledge':
        cacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
        reducer = knowledgeReducer;
        kindURL = 'knowledge';
        kindSingular = 'Knowledge Base';
        kindPlural = 'Knowledge Bases';
        searchKind = 'knowledge';
        icon = "book-spells";
        tryingToGet = knowledgeReducer.tryingToGetKnowledgeByOrganization || knowledgeReducer.tryingToGetMyKnowledge;
        canCreateNew = membership.create_knowledge && organization.counts.knowledge < organization.quotas.knowledge;
        atCreationLimit = organization.counts.knowledge >= organization.quotas.knowledge;
        break;
      case 'inquiries':
        cacheList = Object.keys(inquiryReducer.cache).map(id => inquiryReducer.cache[id]);
        reducer = inquiryReducer;
        kindURL = 'inquiries';
        kindSingular = 'Project';
        kindPlural = 'Projects';
        searchKind = 'projects';
        icon = "lightbulb-on";
        tryingToGet = inquiryReducer.tryingToGetInquiriesByOrganization;
        canCreateNew = membership.create_inquiries && organization.counts.inquiries < organization.quotas.inquiries;
        atCreationLimit = organization.counts.inquiries >= organization.quotas.inquiries;
        break;
    }

    let orgItems = [];
    if(this.props.org_id){

    // if(guiReducer.kindFilters.includes('teams')){
    //   orgItems = orgItems.filter(c => c.myRole.id);
    // }
    // if(guiReducer.kindFilters.includes('mine')){
    //   orgItems = orgItems.filter(c => c.created_by === userReducer.myData.id);
    // }

      if(guiReducer.kindView === 'mine'){
        let itemArray = cacheList.filter(p => p.scope === this.props.org_id);
        orgItems = itemArray.filter(c => c.created_by === userReducer.myData.id || (c.myRole && c.myRole.id));
      } else if(guiReducer.kindView === 'all'){
        orgItems = cacheList.filter(c => (reducer.orgItems[this.props.org_id] || []).includes(c.id));
      }
    }
    orgItems = orgItems.sort((a, b) => {
      if(a.last_updated < b.last_updated) return 1;
      if(a.last_updated > b.last_updated) return -1;
      return 0;
    })
    let totalItems = orgItems.length; 

    // switch(guiReducer.kindSort){
    //   case 'name':
    //     orgItems = orgItems.sort((a, b) => {
    //       if(a.display_name.toLowerCase() < b.display_name.toLowerCase()) return -1;
    //       if(a.display_name.toLowerCase() > b.display_name.toLowerCase()) return 1;
    //       return 0;
    //     })
    //     break;
    //   case 'created_at':
    //     orgItems = orgItems.sort((a, b) => {
    //       if(a.created_at < b.created_at) return 1;
    //       if(a.created_at > b.created_at) return -1;
    //       return 0;
    //     })
    //     break;
    //   case 'last_updated':
    //     orgItems = orgItems.sort((a, b) => {
    //       if(a.last_updated < b.last_updated) return 1;
    //       if(a.last_updated > b.last_updated) return -1;
    //       return 0;
    //     })
    //     break;
    // }


    if(this.props.new){
      return <div className="margin-bottom-3rem">
        <div className="row">
          <div className="col-lg-8 col-md-10">
            {
              this.props.kind === 'components' &&
              <NewComponentForm org_id={this.props.org_id}/>
            }
            {
              this.props.kind === 'knowledge' &&
              <NewKnowledgeForm org_id={this.props.org_id}/>
            }
            {
              this.props.kind === 'projects' &&
              <NewProjectForm org_id={this.props.org_id}/>
            }
            {
              this.props.kind === 'inquiries' &&
              <NewInquiryForm org_id={this.props.org_id}/>
            }
          </div>
        </div>
      </div>
    }


    return <div className="margin-bottom-3rem">
      {/* <h5 className="no-margin-top margin-bottom-1rem text-uppercase text-primary">
        <i className={"fal fa-fw fa-" + icon + " icon-before-text"}/>
        {
          kindPlural
        }
        {
          tryingToGet && 
          <small className=""><i className="far fa-spinner-third fa-spin text-smaller icon-after-text"/></small>
        }
      </h5> */}
      <div>
        {
          atCreationLimit && <Callout
            style="warning"
            dismissable={true}
            title={"You can only create up to " + organization.quotas[kind] + " " + kindPlural + " in this organization. "}
            content={"If you need more, please contact your organization administrator."}
            />
        }
        <div className="margin-bottom-2rem flex-split">
          <div className="list-left">
            <CustomButton
              to={"/workbench/" + this.props.org_id + '/' + kindURL + '/new'}          
              display={<span><i className="far fa-plus icon-before-text"/>New {kindSingular}</span>}
              displayOptions={[
                <span><i className="far fa-plus icon-before-text"/>New Project</span>,
                <span><i className="far fa-plus icon-before-text"/>New Agent</span>,
                <span><i className="far fa-plus icon-before-text"/>New Knowledge Base</span>,
                <span><i className="far fa-plus icon-before-text"/>New Endpoint</span>
              ]}
              color={this.props.kind}
              disabled={!canCreateNew}
              size={'small'}
              />


            {
              !this.props.limit &&
              <div>
                <CustomSelect
                  placeholder="Show..."
                  value={guiReducer.kindView}
                  inline={true}
                  minWidth={300}
                  options={[
                    {value: 'all', label: 'All ' + kindPlural + ' in this organization'},
                    {value: 'mine', label: kindPlural + ' I\'m working on'},
                  ]} 
                  onChange={e => {
                    dispatch(setKindView(e));

                    if(e === 'all'){
                      switch(this.props.kind){
                        case 'components':
                          dispatch(tryToGetComponentsByOrganization({org_id: this.props.org_id}));
                          break;
                        case 'knowledge':
                          dispatch(tryToGetKnowledgeByOrganization({org_id: this.props.org_id}));
                          break;
                        case 'projects':
                          dispatch(tryToGetProjectsByOrganization({org_id: this.props.org_id}));
                          break;
                      }
                    }
                    if(e === 'mine'){
                      switch(this.props.kind){
                        case 'components':
                          dispatch(tryToGetMyComponents({org_id: this.props.org_id}));
                          break;
                        case 'knowledge':
                          // dispatch(tryToGetMyKnowledge({org_id: this.props.org_id}));
                          break;
                        case 'projects':
                          // dispatch(tryToGetMyProjects({org_id: this.props.org_id}));
                          break;
                      }
                    }

                  }}
                  />

              </div>
            }

            {
              // !this.props.limit &&
              // <div>
              //   <CustomSelect
              //     placeholder="Sort by..."
              //     value={guiReducer.kindSort}
              //     inline={true}
              //     minWidth={250}
              //     options={[
              //       {value: 'name', label: 'Sort by Name'},
              //       {value: 'created_at', label: 'Recently Created First'},
              //       {value: 'last_updated', label: 'Last Updated First'}
              //     ]} 
              //     onChange={e => {
              //       dispatch(setKindSort(e));
              //     }}
              //     />
              // </div>
            }
            {
              tryingToGet && <div className="text-muted">
                <i className="fal fa-spinner-third fa-spin icon-before-text"/>loading...
              </div>
            }

            {
              this.props.limit &&
              <CustomButton
                to={"/workbench/" + this.props.org_id + '/' + kindURL}
                display={<span>View All</span>}
                color="transparent"
                size={this.props.limit ? 'small' : undefined}
                />
              
              // <small className="text-muted">Showing {orgItems.length} of {totalItems}</small>
            }
          </div>
          <form  className="list-right list-no-wrap" onSubmit={e => {
              e.preventDefault();
              dispatch(push("/workbench/" + this.props.org_id + "/search?query=" + this.state.localSearchText + '&kinds=' + searchKind));
            }}>
            <CustomField
              inline={true}
              value={this.state.localSearchText}
              placeholder="Search..."
              minWidth={250}
              onChange={e => {
                this.setState({localSearchText: e.value});
              }}
              />
            <CustomButton
              size="icon-only"
              display={<span><i className="far fa-search"/></span>}
              color="black"
              onClick={() => {
                dispatch(push("/workbench/" + this.props.org_id + "/search?query=" + this.state.localSearchText + '&kinds=' + searchKind));
              }}
              />
            <button type="submit" style={{display: 'none'}}/>
          </form>
        </div>
        {
          (totalItems === 0 && !tryingToGet) ? <div className="row row-eq-height">
            <div className="col-md-12 flex-column-stretch">
              <div className="flex-column-center-center">
                <h5 className="text-muted">You haven't created any {kindPlural} yet.</h5>
              </div>
            </div>
          </div>
          :
          <div className="row row-eq-height">
            {
              orgItems.map((kindThing, i)=>{
                if(this.props.limit && i >= this.props.limit) return null;

                return <div className="col-md-4 col-lg-3 col-sm-6" key={i}>
                  <Hydrate type={this.props.kind} id={kindThing.id} mode="thumbnail" obj={kindThing} key={i}/>
                </div>
              })
            }
            {
              (guiReducer.kindFilters.length > 0 && orgItems.length === 0 && totalItems > 0 && !tryingToGet) ? <div className="col-md-12">
                No matches for your current filters, try removing a few.
              </div>
              :
              guiReducer.kindView === 'all' &&
              <div className="col-md-12 flex-column-center-center padding-top-2rem">
                <CustomButton
                  display="Load More"
                  size="small"
                  color="grey"
                  thinking={tryingToGet}
                  onClick={() => {
                    switch(this.props.kind){
                      case 'components':
                        dispatch(tryToGetComponentsByOrganization({org_id: this.props.org_id, startAfter: orgItems[orgItems.length - 1].last_updated}));
                        break;
                      case 'knowledge':
                        dispatch(tryToGetKnowledgeByOrganization({org_id: this.props.org_id, startAfter: orgItems[orgItems.length - 1].last_updated}));
                        break;
                      case 'projects':
                        dispatch(tryToGetProjectsByOrganization({org_id: this.props.org_id, startAfter: orgItems[orgItems.length - 1].last_updated}));
                        break;
                      case 'inquiries':
                        dispatch(tryToGetInquiriesByOrganization({org_id: this.props.org_id, startAfter: orgItems[orgItems.length - 1].last_updated}));
                        break;
                    }
                  }}
                  />
              </div>
            }

            
          </div>
        }

      </div>
      
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, projectReducer, guiReducer, knowledgeReducer, inquiryReducer, componentReducer  } = state;

  return {
    userReducer,
    orgReducer,
    projectReducer,
    guiReducer, 
    knowledgeReducer, 
    inquiryReducer, 
    componentReducer
  }
}

export default connect(mapStateToProps)(WorkbenchKindGallery);
