import React, { Component, useState, useLayoutEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux';
import ReactMarkdown from 'react-markdown';
import slugify from 'slugify';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Footer from '../Footer/Footer'

import './DocsManager.scss';

import CustomField from '../../kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton';
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';

import docs from '../../configs/config.docs'
import FlowRenderer from 'components/FlowBuilder/FlowRenderer';

function generateTableOfContents(markdownText) {
  const lines = (markdownText || "").split('\n');
  const toc = [];
  let currentH2 = null;

  lines.forEach(line => {
    if (line.startsWith('## ')) {
      // This is an H2 header
      const title = line.substring(3); // Remove the '## ' part to get the title
      currentH2 = { title, children: [] };
      toc.push(currentH2); // Add to the ToC array
    } else if (line.startsWith('### ') && currentH2) {
      // This is an H3 header and we have an H2 parent for it
      const title = line.substring(4); // Remove the '### ' part to get the title
      currentH2.children.push({ title }); // Add as a child of the current H2
    }
  });

  return toc;
}


// Custom renderer for links
const renderers = {
  link: ({ href, children }) => {
    // Check if the link is internal or external
    // This example checks if the URL is relative, indicating an internal link
    // Adjust the condition based on your needs, such as checking for a specific domain
    const isInternal = /^\/(?!\/)/.test(href);

    if (isInternal) {
      // Internal link
      return <Link to={href}>{children}</Link>;
    } else {
      // External link - adding rel="noopener noreferrer" is a security best practice for external links
      return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
    }
  },
  h2: ({ node, ...props }) => {
    const slug = slugify(props.children[0]);
    return <h2 id={slug}><a href={`#${slug}`}>{props.children}</a></h2>;
  },
  h3: ({ node, ...props }) => {
    const slug = slugify(props.children[0]);
    return <h3 id={slug}><a href={`#${slug}`}>{props.children}</a></h3>;
  },
  h6: ({ node, ...props }) => {
    return <div className="spacer-2rem"/>
  },
  table: ({node, ...props}) => (
    <table className="table-basic" {...props} />
  ),
  code({node, inline, className, children, ...props}) {
    const match = /language-(\w+)/.exec(className || '');
    
    if(!inline && match && match[1] === 'flow'){
      // render a flow here instead of a code block
      // try to parse the code as JSON
      let flow = null;
      try {
        flow = JSON.parse(children);

        return <div className="box box-no-pad box-bg-gs95" style={{height: 400}}>
          <FlowRenderer  
            nodes={flow.flow_nodes || []}
            links={flow.flow_links || []}
            showMinimap={false}
            simpleMode={true}
            interactive={false}/>
        </div>
      } catch (e) {
        // do nothing
        console.log(e);
      }
    }
    
    return !inline && match ? (
      <CodeHighlighter
        code={children}
        block={true}
        language={match ? match[1] : 'javascript'}
        />
    ) : (
      <code className={'code-styled ' + className} {...props}>
        {children}
      </code>
    )
  }
};


class DocsManager extends Component {
  constructor(props){
    super(props);

    this.state = {
      search: "",
      openedDocs: {}
    }
  }

  componentWillMount(){
    
    const { dispatch, userReducer, page, subpage } = this.props;

    let opened = {};
    for(var i in docs){
      if(page === docs[i].name) opened[docs[i].name] = true;
      if(docs[i].children){
        for(var j in docs[i].children){
          if(subpage === docs[i].children[j].name) opened[docs[i].name] = true;
        }
      }
    }

    if(!page && !subpage){
      dispatch(replace('/docs/' + docs[0].name + '/' + docs[0].children[0].name))
      opened = {
        [docs[0].name]: true,
      }
    } else if(page && !subpage){
      dispatch(replace('/docs/' + page + '/' + docs.find(d => d.name === page).children[0].name))
      opened = {
        [page]: true,
      }
    }

    this.setState({
      openedDocs: opened
    })
  }

  componentWillReceiveProps(nextProps){
    const { dispatch, userReducer, page, subpage } = nextProps;

    if(!page && !subpage){
      dispatch(replace('/docs/' + docs[0].name + '/' + docs[0].children[0].name))
    } else if(page && !subpage){
      dispatch(replace('/docs/' + page + '/' + docs.find(d => d.name === page).children[0].name))
    }
  }

  render(){

    const { dispatch, userReducer, page, subpage } = this.props;

    let currentPage = docs.find(d => d.name === page) || docs[0];
    let currentPageIndex = docs.indexOf(currentPage);
    let currentSubpage;
    let currentSubpageIndex;

    let previousSubpage;
    let nextSubpage;
    let previousPage;
    let nextPage;

    let current = docs[0];
    let content = current.content;
    let parts = [];

    if(currentPage){
      current = currentPage;
      content = currentPage.content;
      if(currentPage.children){
        currentSubpage = currentPage.children.find(child => child.name === subpage);
        currentSubpageIndex = currentPage.children.findIndex(child => child.name === subpage);
        if(currentSubpage){
          current = currentSubpage;
          content = currentSubpage.content;
          parts = currentSubpage.parts;
        }
      }

      if(!currentSubpage && currentPage.children){
        currentSubpage = currentPage.children[0];
        currentSubpageIndex = 0;
      }
    }

    if(currentPageIndex > 0){
      previousPage = docs[currentPageIndex - 1];
    }
    if(currentPageIndex < docs.length - 1){
      nextPage = docs[currentPageIndex + 1];
    }

    if(currentSubpageIndex > 0){
      previousSubpage = currentPage.children[currentSubpageIndex - 1];
      previousPage = currentPage;
    }
    if(currentSubpageIndex < currentPage.children.length - 1){
      nextSubpage = currentPage.children[currentSubpageIndex + 1];
      nextPage = currentPage;
    }
    if(currentSubpageIndex === 0){
      previousSubpage = previousPage && previousPage.children[previousPage.children.length - 1];
    }
    if(currentSubpageIndex === currentPage.children.length - 1){
      nextSubpage = nextPage && nextPage.children[0];
    }

    const searchingFor = this.state.search.toLowerCase();

    let searchedDocs = docs;
    for(var i in docs){
      docs[i].searchFound = docs[i].name.toLowerCase().indexOf(searchingFor) > -1;
      if(docs[i].content && !docs[i].searchFound){
        docs[i].searchFound = docs[i].content.indexOf(searchingFor) > -1;
      }

      for(var j in docs[i].children){

        docs[i].children[j].searchFound = docs[i].children[j].name.toLowerCase().indexOf(searchingFor) > -1;
        if(docs[i].children[j].content && !docs[i].children[j].searchFound){
          docs[i].children[j].searchFound = docs[i].children[j].content.indexOf(searchingFor) > -1;
        }

        if(docs[i].children[j].searchFound) docs[i].searchFound = true;


        for(var k in docs[i].children[j].parts){

          docs[i].children[j].parts[k].searchFound = docs[i].children[j].parts[k].name.toLowerCase().indexOf(searchingFor) > -1;
          if(docs[i].children[j].parts[k].content && !docs[i].children[j].parts[k].searchFound){
            docs[i].children[j].parts[k].searchFound = docs[i].children[j].parts[k].content.indexOf(searchingFor) > -1;
          }

          if(docs[i].children[j].parts[k].searchFound){
            docs[i].children[j].searchFound = true;
            docs[i].searchFound = true;
          }
        }
      }
    }

    // scrape "content" for its use of H2 and H3 tags to create a tree of the document we can use as a table of contents
    const contentTree = generateTableOfContents(content);

    const menuDiv = searchedDocs.map((d, i) => {
      if(this.state.search && !d.searchFound) return;
      if(d.loginOnly && !userReducer.isLoggedIn) return;

      return <div key={i} className="doc-category">
        {
          d.children ? 
          <div onClick={e => {
            let openedDocs = this.state.openedDocs;
            openedDocs[d.name] = !openedDocs[d.name];
            this.setState({openedDocs: openedDocs})
          }} className="doc-link text-uppercase text-semi-muted text-900">
            <small>
              {
                this.state.openedDocs[d.name] ? 
                <i className="fas fa-fw fa-caret-down icon-before-text"/>
                :
                <i className="fas fa-fw fa-caret-right icon-before-text"/>
              }
              {d.display_name}
            </small>
          </div>
          :
          <Link to={'/docs/' + d.name} className={"doc-link text-uppercase " + (currentPage.name === d.name ? "doc-link-selected" : "")}><small className="text-semi-muted">{d.display_name}</small></Link>
        }
        {
          (d.children && this.state.openedDocs[d.name]) && 
          <div className="doc-children">
            {
              d.children.map((child, j)=>{
                  if(child.loginOnly && !userReducer.isLoggedIn) return;
                  if(this.state.search && !child.searchFound) return;
                  if(!currentSubpage) currentSubpage = {}

                  return <div>
                    <Link 
                      key={j} 
                      to={'/docs/' + d.name + '/' + child.name} 
                      className={"doc-child doc-link " + ((currentPage.name === d.name && currentSubpage.name === child.name) ? "doc-link-selected" : "")}
                      onClick={e => {
                        this.setState({showMobileMenu: false});
                        //scroll to top of scrollable div
                        let el = document.getElementById('docs-content-scroll');
                        if(el) el.scrollTop = 0;
                      }}
                      >
                      {child.display_name}
                    </Link>

                    {
                      (currentPage.name === d.name && currentSubpage.name === child.name && child.parts) &&
                      <div className="doc-parts ">
                        {child.parts.map((part, k) => {
                          if(part.loginOnly && !userReducer.isLoggedIn) return;
                          if(this.state.search && !part.searchFound) return;

                          return <div key={k} className="">
                            <a className="doc-link doc-part " href={'/docs/' + currentPage.name + '/' + currentSubpage.name + '#' + part.name}>
                              <small className="fal fa-link icon-before-text"/>
                              {part.display_name}
                            </a>
                          </div>
                        })}
                      </div>
                    }
                  </div>
                
              })
            }
          </div>
        }
      </div>
    })

    return <div className={"docs-manager"}>
        <div className="docs-menu-wrapper d-none d-md-block">
            <div className="docs-menu-inner">
              <div className="padding-1rem">
                <CustomField
                  value={this.state.search}
                  inline={true}
                  placeholder="search"
                  onChange={e => this.setState({search: e.value})}
                  description={this.state.search && <div>
                    <span className="clickable text-hover-danger" onClick={e => this.setState({search: ""})}>clear search</span>
                  </div>}
                  />
              </div>
              {
                this.state.search ? <div className=" padding-left-1rem padding-right-1rem text-uppercase text-semi-muted text-900 ">
                  <small>
                    <i className="far fa-fw fa-search icon-before-text"/>
                    Filtered pages:
                  </small>
                </div>
                :
                <div className=""/>
              }
              <div className="padding-1rem">
                {
                  menuDiv
                }
              </div>

            </div>
            
            
        </div>
        <div className="docs-content-wrapper" id="docs-content-scroll">
         
          
          <div className="docs-content-inner">

            <div className="docs-content-inner-markdown flex-grow scroll-parent">
              <div className="scroll-child">
              <div className="docs-content-header d-md-none">
                  <CustomButton
                    display={<i className="fas fa-fw fa-bars fa-lg"/>}
                    color="grey"
                    size="icon-only"
                    onClick={e => {
                      this.setState({showMobileMenu: !this.state.showMobileMenu})
                    }}
                  />
                  <div className="flex-grow margin-left-1rem">
                    <CustomField
                      value={this.state.search}
                      inline={true}
                      placeholder="search"
                      onChange={e => this.setState({search: e.value, showMobileMenu: true})}
                      description={this.state.search && <div>
                        <span className="clickable text-hover-danger" onClick={e => this.setState({search: ""})}>clear search</span>
                      </div>}
                      />
                  </div>
                </div>
                {
                  (this.state.showMobileMenu) &&
                  <div className="docs-mobile-menu">
                    {menuDiv}
                  </div>
                }
                <div className="padding-3rem">
                  <h1>{current.display_name}</h1>
                  <hr/>
                  <ReactMarkdown allowDangerousHtml={true} components={renderers} remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
                  {
                    parts && parts.map((part, i)=>{
                      return <div key={i} id={part.name}>
                        <div><br/></div>
                        <Link to={'/docs/' + page + '/' + subpage + '#' + part.name} className="link-no-decoration"><h4 className="no-margin-top">{part.display_name}</h4></Link>
                        <ReactMarkdown allowDangerousHtml={true} components={renderers} remarkPlugins={[remarkGfm]}>{part.content}</ReactMarkdown>
                      </div>
                    })
                  }
                  <hr className="hr-mega-pad"/>
                  <div className="flex-split ">
                    {
                      previousSubpage !== undefined ?
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span><i className="far fa-arrow-left icon-before-text"/> {previousSubpage.display_name}</span>}
                        to={'/docs/' + previousPage.name + '/' + previousSubpage.name}
                        onClick={e => {
                          //scroll to top of scrollable div
                          let el = document.getElementById('docs-content-scroll');
                          if(el) el.scrollTop = 0;
                        }}
                        />
                      :
                      <span/>
                    }
                    {
                      nextSubpage !== undefined ?
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span>{nextSubpage.display_name} <i className="far fa-arrow-right icon-after-text"/></span>}
                        to={'/docs/' + nextPage.name + '/' + nextSubpage.name}
                        onClick={e => {
                          //scroll to top of scrollable div
                          let el = document.getElementById('docs-content-scroll');
                          if(el) el.scrollTop = 0;
                        }}
                        />
                      :
                      <span/>
                    }

                </div>
                </div>

                <Footer simple={true}/>
              </div>
            </div>
            <div className="docs-content-inner-toc-wrapper d-none d-lg-block scroll-parent">
              {
                contentTree.length > 0 &&
                <div className={"docs-toc scroll-child"}>
                  <small className="text-900 text-uppercase no-margin text-muted"><i className="fal fa-indent icon-before-text"/>Table of Contents</small>
                  <ul className="ul-iconed">
                    {
                      contentTree.map((h2, i) => {
                        return <li key={i}>
                          <a href={'#' + slugify(h2.title)} className="link-no-decoration">{h2.title}</a>
                          {
                            h2.children.length > 0 &&
                            <ul className="ul-iconed">
                              {
                                h2.children.map((h3, j) => {
                                  return <li key={j}>
                                    <a href={'#' + slugify(h3.title)}>
                                      <i className="fal fa-long-arrow-right icon-before-text"/>
                                      {h3.title}
                                    </a>
                                  </li>
                                })
                              }
                            </ul>
                          }
                        </li>
                      })
                    }
                  </ul>
                </div>
              }
            </div>
            
          </div>
        </div>
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, featuresReducer, guiReducer  } = state;

  return {
    userReducer,
    featuresReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(DocsManager);
