import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetMyComponents,
  tryToGetProject
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Create a component
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestCreateNewComponent = packet => {
  return {
    type: 'REQUEST_CREATE_NEW_COMPONENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveCreateNewComponentSuccess = data => ({
  type: 'RECEIVE_CREATE_NEW_COMPONENT_SUCCESS',
  data
})

export const receiveCreateNewComponentFail = data => ({
  type: 'RECEIVE_CREATE_NEW_COMPONENT_FAIL',
  data
})

export const tryToCreateNewComponent = packet => (dispatch, getState) => {

  dispatch(requestCreateNewComponent(packet));

  let headers = {
    "Content-Type": "application/json"
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }

  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/components', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveCreateNewComponentSuccess(json));
      if(packet.origin_project){
        dispatch(tryToGetProject({id: packet.origin_project}));
        dispatch(push('/workbench/' + json.scope + '/projects/' + packet.origin_project + '/agents/' + json.id)); 
      } else {
        dispatch(push('/workbench/' + json.scope + '/agents/' + json.id));
      }
      dispatch(tryToGetMyComponents({org_id: json.scope}));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveCreateNewComponentFail({errors:e, lastSubmit: packet}));
    })
}
