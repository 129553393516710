import React from 'react';

export const NodeCustomFormatter_request = ({ node, type, component, version }) => {

  let retval = structuredClone(type);

  if(!component || !version) return type;

  let loadedVersion = component.versions.find(v => v.id === version);

  if(!loadedVersion) return type;

  let variables = loadedVersion.variables || [];

  variables.forEach(variable => {
    retval.outputs.push({
      name: 'v_' + variable.key,
      display_name: variable.display_name || '${' + variable.key + '}',
      type: variable.type == "number" ? "number" : "string",
      description: variable.description || "No description provided.",
      additional: true,
      custom: true
    });
  });

  return retval;
}