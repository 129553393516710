import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './MegaMarkdown.scss'; // Assuming SCSS module usage

import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';


function useThrottledCallback(callback, delay) {
  const lastCallRef = useRef(0);
  const requestRef = useRef();

  return useCallback((...args) => {
    const now = Date.now();
    const timeSinceLastCall = now - lastCallRef.current;

    if (timeSinceLastCall >= delay) {
      lastCallRef.current = now;
      callback(...args);
    } else {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
      requestRef.current = requestAnimationFrame(() => {
        lastCallRef.current = now;
        callback(...args);
      });
    }
  }, [callback, delay]);
}

// This is the placeholder image URL you want to use
const placeholderImageUrl = '/img/loading_image.png';

// Custom renderer for links
const renderers = {
  table: ({ node, ...props }) => (
    <table className="table-basic" {...props} />
  ),
  paragraph: 'span',
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');

    return !inline ? (
      <CodeHighlighter
        code={children}
        block={true}
        language={match ? match[1] : 'javascript'}
      />
    ) : (
      <code className={'code-styled ' + className} {...props}>
        {children}
      </code>
    );
  },

  // make links all target="_blank"
  a: ({ node, ...props }) => {
    if (props.href) {
      // if the markdown we're displaying is a URL and nothing else    
      
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    }
    return <a {...props} />;
  }
};

function getMarkdownForImageURL(url) {
  // Regular expression to check if the URL is a valid image link
  const imageUrlPattern = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|webp|svg)(\?.*)?)$/i;

  // Test if the URL matches the pattern
  if (imageUrlPattern.test(url)) {
    return `![Image](${url})`;
  }
  
  // If it's not a valid image URL, return null or a relevant message
  return null;
}


function preprocessMarkdown(markdown) {
  // safely convert it to a string
  markdown = String(markdown);

  markdown = getMarkdownForImageURL(markdown) || markdown;

  let retval = markdown.replace(/!\[.*?\]\([^)\s]*$/, `![Placeholder](${placeholderImageUrl})`);
  
  // if retval can be parsed as JSON, then it's a valid JSON string so lets markup it as code
  let isJson = false;
  try {
    JSON.parse(retval);
    isJson = true;
    retval = '```json\n' + retval + '\n```';
  } catch (e) {
    // do nothing
  }

  return {
    markdown: retval,
    isJson: isJson
  }

}

const MegaMarkdown = React.memo(({ text, autoBox }) => {

  const [processedText, setProcessedText] = useState(text);
  const [isJson, setIsJson] = useState(false);

  // Create a throttled function for processing text
  const throttledProcess = useThrottledCallback((newText) => {
    let processed = preprocessMarkdown(newText);
    setProcessedText(processed.markdown);
    setIsJson(processed.isJson);
  }, 500); // Adjust delay as needed

  useEffect(() => {
    throttledProcess(text);
  }, [text, throttledProcess]);
  
  let retval = <div className="mega-markdown">
      <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
        {processedText}
      </ReactMarkdown>
    </div>

  if(autoBox){
    if(isJson){
      return retval;
    }

    return <div className="box box-light-border box-no-shadow no-margin-bottom  bg-gs95">
      {retval}
    </div>
  }

  return retval;

});

MegaMarkdown.propTypes = {
  text: PropTypes.string
};

MegaMarkdown.defaultProps = {
  text: ''
};

export default MegaMarkdown;
