const nodeCategories = [
    {
      name: 'i/o',
      display_name: 'I/O',
      description: 'Starting and ending points for logic & content through your agent.',
      icon: 'arrow-to-right',
      nodes: []
    },
    {
      name: 'llm',
      display_name: 'Large Language Models',
      description: 'Foundational LLMs to customize and leverage, add multiple to create more complex responses.',
      icon: 'brain',
      nodes: []
    },
    // {
    //   name: 'plugin',
    //   display_name: 'Plugins',
    //   icon: 'puzzle-piece',
    //   nodes: []
    // },
    {
      name: 'messages',
      display_name: 'Messages',
      description: 'Create, modify, organize, and manipulate messages in the context window of your agent.',
      icon: 'comments-alt',
      nodes: []
    },
    {
      name: 'knowledge',
      display_name: 'Knowledge',
      description: 'Connect your Knowledge Bases to provide information to your agent.',
      icon: 'book-spells',
      nodes: []
    },
    {
      name: 'external',
      display_name: 'Web',
      description: 'Connect to external APIs and services to give your agent more capabilities.',
      icon: 'globe',
      nodes: []
    },
    {
      name: 'flows',
      display_name: 'Flows',
      description: 'Embed other flows from your project to create more complex logic.',
      icon: 'project-diagram',
      nodes: []
    },
    {
      name: 'logic',
      display_name: 'Logic',
      description: 'Building block boolean logic nodes to create complex decision trees.',
      icon: 'question',
      nodes: []
    },
    {
      name: 'math',
      display_name: 'Math',
      description: 'Mathematical operations to manipulate data and make decisions.',
      icon: 'calculator-alt',
      nodes: []
    },
    {
      name: 'string',
      display_name: 'String',
      description: 'String manipulation nodes to transform and format text data.',
      icon: 'text-size',
      nodes: []
    },
    {
      name: 'data',
      display_name: 'Data',
      description: 'Data manipulation nodes to transform and format structured data.',
      icon: 'stream',
      nodes: []
    },
    {
      name: 'time',
      display_name: 'Time',
      description: 'Generation and formatting nodes to work with dates and times.',
      icon: 'clock',
      nodes: []
    },
    {
      name: 'vectors',
      display_name: 'Vectors',
      description: 'Vector and array manipulation nodes to work with embeddings.',
      icon: 'expand-arrows',
    },
  ]

export default nodeCategories;