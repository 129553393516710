import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import { 
  addToast, 
  updateRateLimit 
} from '../actions.export'


////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Add content to a component's draft
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDemoComponent = packet => {
  return {
    type: 'REQUEST_DEMO_COMPONENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDemoComponentSuccess = data => ({
  type: 'RECEIVE_DEMO_COMPONENT_SUCCESS',
  data
})

export const receiveDemoComponentFail = data => ({
  type: 'RECEIVE_DEMO_COMPONENT_FAIL',
  data
})

// Action creator for streaming updates
export const streamStart = (data) => ({
  type: 'DEMO_STREAM_START',
  data,
});

// Action creator for streaming updates
export const streamUpdate = (data) => ({
  type: 'DEMO_STREAM_UPDATE',
  data,
});

export const bulkStreamUpdate = (data) => ({
  type: 'DEMO_BULK_STREAM_UPDATE',
  data,
});

// Action creator for streaming updates
export const streamComplete = (data) => ({
  type: 'DEMO_STREAM_COMPLETE',
  data,
});

// Action creator for stream errors
export const streamError = (error) => ({
  type: 'DEMO_STREAM_ERROR',
  error,
});

// Action creator for stream closed
export const streamClosed = () => ({
  type: 'DEMO_STREAM_CLOSED',
});

export const tryToDemoComponent = (packet, useStream = false, trial = false) => (dispatch, getState) => {

  let headers = {
    "Content-Type": "application/json"
  }
    
  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }

  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;  
  
  let demoAuth = Cookies.get(process.env.REACT_APP_COOKIE_DEMO_AUTH)
  if(demoAuth){
    headers["X-demo-auth"] = demoAuth;
  }

  let url = `/api/components/${trial ? 'public' : 'demo'}/${packet.id}/${packet.version}`;

  if(useStream){
    packet.data.stream = true;
    if(process.env.NODE_ENV === 'development'){
      url = 'http://localhost:8001' + url;
    }
  }


  
  if(useStream){
    // Initialize streaming mode
    dispatch(streamStart(packet));

    let lastDispatchUpdate = 50;
    let dispatchUpdateFrequency = 0; // ms
    let dispatchUpdateCache = [];

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(packet.data),
    })
    .then(response => {
      if (!response.ok) {
        // dispatch(addToast({type:'error', title: <span className="text-danger">An error occurred</span>}));
        // throw new Error('Network response was not ok');
      }

      // Capture rate limit headers
      const rateLimit = response.headers.get('Ratelimit');
      // in the format of limit=10, remaining=9, reset=86400
      // lets split it and turn it into an object
      const rateLimitParts = rateLimit.split(',').map(part => part.trim());
      const rateLimitObj = rateLimitParts.reduce((acc, part) => {
        const [key, value] = part.split('=');
        acc[key] = value;
        return acc;
      }, {});

      let path = (trial ? 'public' : 'demo') +  '/' + packet.id + '/' + packet.version;

      // if you aren't logged in, then we are going to just put this on the public trial path that is shared across all public flow pages
      if(!getState().userReducer.isLoggedIn){
        path = 'public/tests';
      }

      dispatch(updateRateLimit({
        limit: rateLimitObj.limit,
        remaining: rateLimitObj.remaining,
        reset: rateLimitObj.reset,
        path: path
      }))


      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

      let buffer = '';
      let eventType = null;
      let eventData = [];

      function processEvent() {
        if (eventType && eventData.length) {
          const dataString = eventData.join('\n').trim(); // Join with newline, JSON might include escaped newlines
          // console.log(`Processing event: ${eventType}`, dataString); // Debugging output
          try {
            const dataPacket = JSON.parse(dataString);
            dispatchEvent(eventType, dataPacket, dispatch);
          } catch (error) {
            // console.error('Failed to parse JSON', error, dataString);
          }
        }
        eventType = null;
        eventData = [];
      }

      function dispatchEvent(type, dataPacket, dispatch) {
        // console.log(`Dispatching event: ${type}`, dataPacket); // Debugging output
        const action = {
          'progress': () => dispatch(streamUpdate({
            id: packet.id,
            version: packet.version,
            eventType: type, 
            dataPacket
          })),
          'errorEvent': () => {
            // console.log('errorEvent', dataPacket); // Debugging output
            dispatch(addToast({type:'error', title: <span className="text-danger">An error occurred</span>, detail: dataPacket.message ? dataPacket.message : dataPacket}));
            dispatch(streamError({
              id: packet.id,
              version: packet.version,
              eventType: type, 
              dataPacket
            }));
          },
          'complete': () => dispatch(streamComplete({
            id: packet.id,
            version: packet.version,
            eventType: type, 
            dataPacket
          })),
          'outputProgress': () => { // Custom event type
            console.log('outputProgress', dataPacket); // Debugging output
            // dispatch(streamUpdate({eventType: type, dataPacket}));
            let now = Date.now();
            dispatchUpdateCache.push({eventType: type, dataPacket});
            if(now - lastDispatchUpdate > dispatchUpdateFrequency){
              lastDispatchUpdate = now;
              dispatch(bulkStreamUpdate({
                id: packet.id,
                version: packet.version,
                updates: dispatchUpdateCache
              }));
              dispatchUpdateCache = [];
            } else {
              // console.log('waiting to dispatch');
            }
          },
          // 'response': () => { // Custom event type
          //   dispatch(streamUpdate({eventType: type, dataPacket}));
          // }
        };
        if (action[type]) {
          action[type]();
        } else {
          console.error(`Unhandled event type: ${type}`); // Debugging output
        }
      }

      (function read() {
        reader.read().then(({done, value}) => {
          
          if (done) {
            if (buffer.trim()) {
              buffer += '\n\n'; // Append to trigger final processing
            }
            if (eventType) {
              processEvent(); // Process the last event if any
            } else {
              // attempt to parse the json of the buffer to check for any errors
              try {
                if(buffer.trim().length > 0){
                  const dataPacket = JSON.parse(buffer.trim());
                  dispatchEvent('errorEvent', dataPacket, dispatch);
                }
              } catch (error) {
                console.error('Failed to parse JSON', error, buffer);
              }
            }
            dispatch(streamClosed());
            return;
          }

          buffer += decoder.decode(value, {stream: true});
          // console.log(buffer);
          const lines = buffer.split('\n');
          buffer = lines.pop(); // Retain incomplete line

          lines.forEach(line => {
            if (line.startsWith('event: ')) {
              if (eventType !== null) {
                processEvent(); // Process the previous event
              }
              eventType = line.substring(7).trim(); // Get event type
            } else if (line.startsWith('data: ')) {
              eventData.push(line.substring(6)); // Accumulate data
            } else if (line.trim() === '') {
              processEvent(); // Process the previous event
            }
          });

          read();
        }).catch(error => {
          console.error('Stream read error', error); // Debugging output
          dispatch(streamError(error));
        });
      })();

    })
    .catch(error => {
      console.error('Fetch error', error); // Debugging output
      dispatch(streamError(error));
    });

    
  } else {

    // Initialize streaming mode
    dispatch(requestDemoComponent(packet));
    
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(packet.data),
        headers: headers
      })
      .then((response) => {
        let json = response.json();
        if (response.status >= 200 && response.status < 300) return json;
        return json.then(Promise.reject.bind(Promise));
      })
      .then(function(json){
        dispatch(receiveDemoComponentSuccess(json));
      })
      .catch(e => {
        forceLogoutHandler(e);
        dispatch(addToast({type:'error', title: <span className="text-danger">An error occurred</span>, detail: e.message ? e.message : e}));
        dispatch(receiveDemoComponentFail({errors:e, lastSubmit: packet}));
      })
      ;
  }
}
