import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Hydrate from 'components/Hydrate/Hydrate.js'
import FlowRenderer from 'components/FlowBuilder/FlowRenderer'
import BetterChat from 'components/BetterChat/BetterChat.js'
import BetterPrompt from 'components/BetterPrompt/BetterPrompt.js'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'

import {
  tryToGetPublicPublishedComponent,
  tryToDemoComponent
} from '../actions/actions.export'

import BetterContextOnly from 'components/BetterContextOnly/BetterContextOnly.js'

class FlowPublicRoute extends Component {
  constructor(props){
    super(props);

    this.state = {
      tryMode: false,
      messages: [],
      lastPacketReceived: null,
      lastUpdate: 0
    }
  }
  

  componentDidMount(){
  }

  componentWillMount(){
    const { dispatch, component_id, version } = this.props;

    dispatch(tryToGetPublicPublishedComponent({
      id: component_id,
      version: version
    }))
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.componentReducer.publicCache[this.props.component_id] && !this.state.component){

      // prep variables object
      let variables = {};
      let version = nextProps.version || nextProps.componentReducer.publicCache[this.props.component_id].current_version;
      let loadedVersion = nextProps.componentReducer.publicCache[this.props.component_id].versions.find(v => v.id === version);
      console.log(loadedVersion, version);
      if(loadedVersion && loadedVersion.variables){
        loadedVersion.variables.forEach(v => {
          console.log(v);
          if(v.default !== undefined){
            variables[v.key] = v.default;
          }
        })
      }

      this.setState({
        component: nextProps.componentReducer.publicCache[this.props.component_id],
        variables: variables
      })

      window.document.title = nextProps.componentReducer.publicCache[this.props.component_id].display_name  + " - ZeroWidth";
    }

  }

  

  render(){
    const { dispatch, userReducer, component_id, subpage, componentReducer, sharedReducer } = this.props;

    let component = componentReducer.publicCache[component_id];
    let loadedVersion = null;

    let version = this.props.version;
    if(!version && component){
      version = component.current_version;
    }

    if(component){
      loadedVersion = component.versions.find(v => v.id === version);
    }

    let demoCache = componentReducer.demoCache[component_id + '_' + version];
    let response;

    let highlightedNodeIds = [];
    if(demoCache && demoCache.length > 0){
      let mostRecent = demoCache[demoCache.length - 1];
      
      if(mostRecent.updates && !mostRecent.response){
        // grab the most recent update node_id
        let lastUpdate = mostRecent.updates[mostRecent.updates.length - 1];
        if(lastUpdate && lastUpdate.eventType === 'progress'){
          highlightedNodeIds = [lastUpdate.dataPacket.node_id];
        }
      }

      if(mostRecent.response){
        response = mostRecent.response;
      }
    }

    let triesLeft;

    // find this path in sharedReducer.rateLimitCache
    let path = 'public/' + component_id + '/' + version;

    // if you aren't logged in, then the path is shared across all public flow pages
    if(!userReducer.isLoggedIn){
      path = 'public/tests';
    }

    if(sharedReducer.rateLimitCache[path]){
      let rateLimit = sharedReducer.rateLimitCache[path];
      triesLeft = parseInt(rateLimit.remaining);
    }

    let guiToShow;
    
    let dependencyList = [];
    let contextVariableDiv;
    if(loadedVersion){
      if(component.type){
        guiToShow = component.type;
      }
  
      loadedVersion.flow_nodes.forEach(n => {
        if(n.dependency && dependencyList.indexOf(n.dependency) === -1){
          dependencyList.push(n.dependency);
        }
      })

      // look for a request flow node
      let requestNode = loadedVersion.flow_nodes.find(n => n.type.startsWith('request'));
      
      if(requestNode){
        switch(requestNode.type){
          case 'request':
            guiToShow = "chat";
            break;
          case 'request_single_prompt':
            guiToShow = "single-prompt";
            break;
          case 'request_context_only':
            guiToShow = "madlib";
            break;
        }
      } else {
        guiToShow = "madlib";
      }

      if(loadedVersion.variables){
        contextVariableDiv = loadedVersion.variables.map((variable, i) => {
          return <div key={i} className={""}>
            {
              variable.type === 'text' &&
              <CustomField
                name={variable.key}
                value={this.state.variables[variable.key]}
                label={variable.display_name || '${' + variable.key + '}'}
                description={variable.description || " "}
                placeholder={variable.placeholder || " "} 
                minLength={variable.min_length}
                maxLength={variable.max_length}
                disabled={componentReducer.tryingToDemoComponent}
                onChange={e => {
                  let variables = this.state.variables;
                  variables[variable.key] = e.value;
                  this.setState({
                    variables: variables
                  })
                }}
                />
            }

            {
              variable.type === 'paragraph' &&
              <CustomField
                name={variable.key}
                value={this.state.variables[variable.key]}
                label={variable.display_name || '${' + variable.key + '}'}
                description={variable.description || " "}
                placeholder={variable.placeholder || " "} 
                minLength={variable.min_length}
                maxLength={variable.max_length}
                disabled={componentReducer.tryingToDemoComponent}
                rows={5}
                onChange={e => {
                  let variables = this.state.variables;
                  variables[variable.key] = e.value;
                  this.setState({
                    variables: variables
                  })
                }}
                />
            }

            {
              variable.type === 'number' &&
              <CustomField
                name={variable.key}
                type="number"
                value={this.state.variables[variable.key]}
                label={variable.display_name || '${' + variable.key + '}'}
                description={variable.description || " "}
                placeholder={variable.placeholder || " "} 
                min={variable.min}
                max={variable.max}
                disabled={componentReducer.tryingToDemoComponent}
                onChange={e => {
                  let variables = this.state.variables;
                  variables[variable.key] = e.value;
                  this.setState({
                    variables: variables
                  })
                }}
                />
            }

            {
              variable.type === 'select' &&
              <CustomSelect
                name={variable.key}
                value={this.state.variables[variable.key]}
                label={variable.display_name || '${' + variable.key + '}'}
                description={variable.description || " "}
                placeholder={variable.placeholder || " "} 
                options={variable.options || []}
                disabled={componentReducer.tryingToDemoComponent}
                onChange={e => {
                  let variables = this.state.variables;
                  variables[variable.key] = e;
                  this.setState({
                    variables: variables
                  })
                }}
                />
            }
          </div>
        })
      }
    }


    return <div className={"hf-stretched hf-stretched-noscroll"}>
      <Header borderless={true} whiteBackground={true}/>
      <Modal
        show={this.state.showContextModal}
        exitable={true}
        onExit={e => this.setState({showContextModal: false})}
        acceptable={true}
        onAccept={e => this.setState({showContextModal: false})}
        acceptButtonLabel="Use these values"
        content={<div>
          <h4 className="no-margin-top">
            Context Variables
          </h4>
          {
            loadedVersion && loadedVersion.variables.length > 0 ?
            <div>
              <p>
                The creator of this flow has declared the following context variables, which might be used throughout the flow to customize its behavior or output.<br/><br/>You can edit these values to see how the flow behaves with different inputs.
              </p>
            </div>
            :
            <div>
              <p>
                This flow does not have any context variables.
              </p>
            </div>
          }
          {contextVariableDiv}
        </div>}
        />
      {
        componentReducer.tryingToGetPublicPublishedComponent ? <div className="body flex-column-center-center">
          <div className="loading"><i className="far fa-spinner-third fa-spin"/></div>
        </div>
        :
        (component) ?
        <div className="body">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="flex-split flex-split-align-end">
                  <div className="list-left list-left-no-wrap">
                    <div className="text-ellipsis-2-lines">
                      <h1 className="margin-bottom-1rem margin-top-1rem">{component.display_name}</h1>
                      <p className="no-margin-bottom">
                        {component.description || <span className="text-muted">No description provided by the author.</span>}
                      </p>
                    </div>
                  </div>
                  <div className="list-right list-right-no-wrap">
                    
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            {
              this.state.tryMode ?
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="flex-split">
                    <div className="list-left">
                      <CustomButton
                        display={<span>
                          <i className="far fa-arrow-left icon-before-text"/>
                          Back
                        </span>}
                        color="grey"
                        onClick={e => {
                          this.setState({
                            tryMode: false
                          })
                        }}
                        />
                      
                    </div>
                    <div className="list-right">
                      <small>
                        {triesLeft > 0 &&
                          <span className="text-muted">
                            &nbsp;{triesLeft} free run{triesLeft !== 1 && "s"} left
                          </span>
                        }
                        {triesLeft <= 0 &&
                          <span className="text-danger">
                            &nbsp;0 free runs left
                          </span>
                        }
                      </small>
                      {
                        <CustomButton
                          display={<span>
                            <i className="far fa-brackets-curly icon-before-text"/>
                            Edit Context
                          </span>}
                          color="black"
                          disabled={loadedVersion.variables.length === 0}
                          onClick={e => {
                            this.setState({
                              showContextModal: true
                            })
                          }}
                          />
                      }
                    </div>
                  </div>
                  {
                    triesLeft === 0 && !userReducer.isLoggedIn &&
                    <div className="margin-top-2rem">
                      <Callout
                        style="warning"
                        title="Create an account to keep running this flow"
                        content={<div>
                          <p>
                            You have reached the limit of free {
                              component.type === 'madlib' ? "runs" : "messages"
                            } for this flow. Create an account to try more flows and build your own.
                          </p>
                          <div className="list-right">
                            <Link className="" to="/signin">
                              Sign In
                            </Link>
                            <CustomButton
                              display="Create an Account"
                              color="success"
                              to="/signup"
                              />
                          </div>
                        </div>
                        }
                        />
                    </div>
                  }
                  {
                    triesLeft === 0 && userReducer.isLoggedIn &&
                    <div className="margin-top-2rem">
                      <Callout
                        style="warning"
                        dismissable={true} 
                        title="No more free runs left"
                        content={<div>
                          <p>
                            You have reached the limit of free runs for this flow. To continue, use this flow as a template to run it with your own keys or account credits.
                          </p>
                          <div className="list-right">
                            <CustomButton
                              display={<span>
                                Use as a template (soon)
                                <i className="far fa-external-link icon-after-text"/>
                              </span>}
                              color="black"
                              disabled={true}
                              onClick={e => {
                                if(userReducer.isLoggedIn){
                                  // navigate to the flow builder with this component id as a parameter in a new tab
                                  window.open('/workbench/GBNLqai2Ae9VYl6yxP9h/agents/new?template=' + component_id, '_blank');
                                } else {
                                  dispatch(push('/sign-in'));
                                }
                              }}
                              />
                          </div>
                        </div>
                        }
                        />
                    </div>
                  }
                  {
                    guiToShow === 'single-prompt' && <div className="margin-top-2rem">
                      <BetterPrompt
                        demoCache={componentReducer.demoCache[component_id + '_' + version]}
                        variables={this.state.variables}
                        component={component}
                        component_id={component_id}
                        version={version}
                        publicDemo={true}
                        />
                    </div>
                  }
                  {
                    guiToShow === 'madlib' && <div className="margin-top-2rem">
                      {/* <h5 className="margin-bottom-05rem">Output:</h5>
                      <div className="box box-light-border box-no-shadow" style={{height: 415}}>
                        {
                          response ? <MegaMarkdown text={response.output_data.content}/> 
                          : 
                          <p className="text-center text-muted">
                            Click the <strong><i className="fas fa-play fa-fw"/></strong> button to run this flow.
                          </p>
                        }
                      </div> */}
                      <BetterContextOnly
                        demoCache={componentReducer.demoCache[component_id + '_' + version]}
                        variables={this.state.variables}
                        component={component}
                        component_id={component_id}
                        version={version}
                        publicDemo={true}
                        />
                    </div>
                  }

                  {
                    guiToShow === 'chat' && <div className="margin-top-2rem">
                      <div className="box box-half-pad box-light-border box-no-shadow" style={{height: 465}}>
                        <BetterChat
                          demoCache={componentReducer.demoCache[component_id + '_' + version]}
                          variables={this.state.variables}
                          component={component}
                          component_id={component_id}
                          version={version}
                          publicDemo={true}
                          />
                      </div>
                    </div>
                  }
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="box box-no-pad box-no-border overflow-hidden" style={{height: 550}} key={this.state.tryMode}>
                    {
                      loadedVersion &&
                      <FlowRenderer
                        component_id={component_id}
                        component_version={loadedVersion.id}
                        nodes={loadedVersion.flow_nodes}
                        links={loadedVersion.flow_links}
                        interactive={false}
                        highlightedNodeIds={highlightedNodeIds}
                        />
                    }
                  </div>
                  <div className="list-right">
                    <Link to="/contact" className="link-no-decoration">
                      <small className="text-muted clickable text-hover-black">
                        <i className="far fa-flag icon-before-text"/>
                        Report an issue
                      </small>
                    </Link>
                  </div>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-5">
                  <CustomButton
                    display={<span>
                      Try it out!
                    </span>}
                    color="success"
                    block={true}
                    onClick={e => {
                      this.setState({
                        tryMode: true
                      })
                    }}
                    />
                  <CustomButton
                    display={<span>
                      Use as a template (soon)
                      <i className="far fa-external-link icon-after-text"/>
                    </span>}
                    disabled={true}
                    color="black"
                    block={true}
                    />
                  <div className="spacer-2rem"/>
                  <div className="flex-split-list">
                    <h6 className="no-margin">Created by:</h6>
                    <Hydrate id={component.created_by} type="user" mode="avatar" size={20}/>
                  </div>
                  <div className="flex-split-list">
                    <h6 className="no-margin">Version:</h6>
                    <p className="no-margin">{version}</p>
                  </div>
                  <div className="flex-split-list">
                    <h6 className="no-margin">Published:</h6>
                    <p className="no-margin">{moment(loadedVersion.created_at).fromNow()}</p>
                  </div>
                  <div className="flex-split-list">
                    <h6 className="no-margin">Context Variables:</h6>
                    <p className="no-margin">{loadedVersion.variables.length}</p>
                  </div>
                  <div className="flex-split-list">
                    <h6 className="no-margin">Nodes / Links:</h6>
                    <p className="no-margin">{loadedVersion.flow_nodes.length} / {loadedVersion.flow_links.length}</p>
                  </div>

                  <div className="flex-split-list">
                    <h6 className="no-margin">Third-party Dependencies:</h6>
                    <p className="no-margin">{
                      dependencyList.length > 0 ?
                      <div className="list-right">
                        {
                          dependencyList.map((d, i) => {
                            return <img src={"/img/dependencies/" + d + "_logo_small.png"} width="25px" height="25px"/>
                          })
                        }
                      </div>
                      :
                      <span className="text-tag">NO</span>
                    }
                    </p>
                  </div>

                  <div className="flex-split-list">
                    <h6 className="no-margin">Makes custom HTTP calls:</h6>
                    <p className="no-margin">{loadedVersion.flow_nodes.find(n => n.type === "http-request") ? <span className="text-tag text-tag-danger">YES</span>:<span className="text-tag">NO</span>}</p>
                  </div>


                </div>
                <div className="col-xl-9 col-lg-8 col-md-7">
                  <div className="box box-no-pad box-no-border overflow-hidden" style={{height: 550}}>
                  {
                    loadedVersion &&
                    <FlowRenderer
                      nodes={loadedVersion.flow_nodes}
                      links={loadedVersion.flow_links}
                      interactive={false}
                      />
                  }
                  </div>
                  <div className="list-right">
                    <Link to="/contact" className="link-no-decoration">
                      <small className="text-muted clickable text-hover-black">
                        <i className="far fa-flag icon-before-text"/>
                        Report an issue
                      </small>
                    </Link>
                  </div>
                </div>
              </div>
              
            }
          </div>
        </div>
        :
        <div className="body flex-column-center-center">
          <h1 className="no-margin-bottom">404</h1>
          <p>Hmm, can't find that one...</p>
        </div>
      }
      <div className="">
        <Footer simple={true}/>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, guiReducer, componentReducer, sharedReducer } = state;

  return {
    userReducer,
    componentReducer,
    sharedReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(FlowPublicRoute);

  