import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get components that a user has specific access to
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetMyComponents = packet => {
  return {
    type: 'REQUEST_GET_MY_COMPONENTS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetMyComponentsSuccess = data => ({
  type: 'RECEIVE_GET_MY_COMPONENTS_SUCCESS',
  data
})

export const receiveGetMyComponentsFail = data => ({
  type: 'RECEIVE_GET_MY_COMPONENTS_FAIL',
  data
})

export const tryToGetMyComponents = packet => (dispatch, getState) => {

  dispatch(requestGetMyComponents(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;


  return fetch('/api/components/org/' + packet.org_id + '/mine', {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetMyComponentsSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetMyComponentsFail({errors:e, lastSubmit: packet}));
    })
}
