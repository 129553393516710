import { NodeRenderer_openai_chat } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_openai_chat';
import { NodeRenderer_openai_embedding } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_openai_embedding';
import { NodeRenderer_text } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_text';
import { NodeRenderer_prompt } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_prompt';
import { NodeRenderer_prompt_role_setter } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_prompt_role_setter';
import { NodeRenderer_key_value_maker } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_key_value_maker';
import { NodeRenderer_slider } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_slider';
import { NodeRenderer_boolean } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_boolean';
import { NodeRenderer_monitor } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_monitor';

import { NodeCustomFormatter_request } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_request';


const customRenderDictionary = {
  'openai_chat': NodeRenderer_openai_chat,
  'openai_embedding': NodeRenderer_openai_embedding,
  'text': NodeRenderer_text,
  'prompt': NodeRenderer_prompt,
  'prompt_role_setter': NodeRenderer_prompt_role_setter,
  'key_value_maker': NodeRenderer_key_value_maker,
  'slider': NodeRenderer_slider,
  'boolean': NodeRenderer_boolean,
  'monitor': NodeRenderer_monitor
}

const customFormatterDictionary = {
  'request': NodeCustomFormatter_request,
  'request_single_prompt': NodeCustomFormatter_request,
  'request_context_only': NodeCustomFormatter_request,

}

const nodeHeaderHeight = 36;
const nodePaddingPerPort = 22;
const nodeWidth = 250;
const nodePluginSpacing = 30;
const nodePluginDropZoneHeight = 125;

const getHydratedType = ({node, types, component, version}) => {
  let t = types.find(t => t.name === node.type);

  if(customFormatterDictionary[node.type]){
    t = customFormatterDictionary[node.type]({
      node: node,
      type: t,
      component: component,
      version: version
    });
  }

  return t;
}

const getHydratedFlowNodeLibrary = ({defaultNodes = [], component, version}) => {
  let hydratedTypes = [];

  let nodes = JSON.parse(JSON.stringify(defaultNodes));

  nodes.forEach(t => {

    let nt = JSON.parse(JSON.stringify(t));
    
    if(customFormatterDictionary[t.name]){
      nt = customFormatterDictionary[t.name]({
        node: {},
        type: nt,
        component: component,
        version: version
      });
    }

    hydratedTypes.push(nt);

  });

  return hydratedTypes;
}

const convertComponentToNode = (component) => {

  let inputs = [];
  
  // grab the current version of the component
  if(!component.current_version){
    return null;
  }

  let version = component?.versions?.find(v => v.id === component.current_version);

  if(version && version.flow_nodes){

    let inputNode = version.flow_nodes.find(n => n.type.startsWith('request'));
    
    if(inputNode){

      switch(inputNode.type){
        case 'request':
          inputs.push({
            name: 'message',
            display_name: 'Messages',
            type: 'message or messages',
            description: 'The message to send to this agent.',
          });
          break;
        case 'request_single_prompt':
          inputs.push({
            name: 'prompt',
            display_name: 'Prompt',
            type: 'string',
            description: 'The prompt to send to this agent.'
          });
          break;
      }
    }

    if(version.variables && version.variables.length > 0){

      inputs.push({
        name: 'variables',
        display_name: 'All Context Variables',
        type: 'object',
        description: 'Use this input to pass an object containing all variable values to this agent or use the individual variable inputs below.',
        optional: true
      })


      version.variables.forEach(v => {
        inputs.push({
          name: 'v_' + v.key,
          display_name: v.display_name || '${' + v.key + '}',
          type: v.type === 'number' ? 'number' : 'string',
          description: v.description,
          additional: true,
          optional: true
        });
      });
    }

  }

  return {
    id: component.id,
    name: 'component_' + component.id,
    display_name: component.display_name,
    description: component.description,
    category: 'flows',
    is_plugin: true,
    inputs: inputs,
    outputs: [
      {
        name: 'content',
        display_name: 'Content',
        type: 'string',
        description: 'The final generated content from this flow.'
      },
      {
        name: 'message',
        display_name: 'Message',
        type: 'message',
        description: 'The complete generated message object from this flow.'
      },
      {
        name: 'metadata',
        display_name: 'Metadata',
        type: 'object',
        description: 'Any additional metadata or information about from flow.'
      },
      {
        name: "plugin_config",
        display_name: "Plugin Config",
        type: "plugin",
        hidden: true
      }
    ],
    settings: [
      {
        name: 'component_id',
        display_name: 'Component ID',
        type: 'string',
        default: component.id,
      }
    ]
  }
}

const filterNodesByDragBox = ({ dragX, dragY, dragWidth, dragHeight, objects }) => {
  const isWindowSelection = dragWidth > 0; // If true, it's window selection (left to right), otherwise it's crossing selection (right to left)

  // Calculate actual bounds of the drag box based on drag direction
  const dragBoxLeft = dragWidth > 0 ? dragX : dragX + dragWidth;
  const dragBoxTop = dragHeight > 0 ? dragY : dragY + dragHeight;
  const dragBoxRight = dragWidth > 0 ? dragX + dragWidth : dragX;
  const dragBoxBottom = dragHeight > 0 ? dragY + dragHeight : dragY;

  return objects.filter(object => {
    const objectLeft = object.x;
    const objectTop = object.y;
    const objectRight = objectLeft + object.width;
    const objectBottom = objectTop + object.height;

    if (isWindowSelection) {
      // Window selection: Select objects completely within the drag box
      return (
        objectLeft >= dragBoxLeft &&
        objectRight <= dragBoxRight &&
        objectTop >= dragBoxTop &&
        objectBottom <= dragBoxBottom
      );
    } else {
      // Crossing selection: Select objects crossing or inside the drag box
      return !(
        objectRight < dragBoxLeft ||
        objectLeft > dragBoxRight ||
        objectBottom < dragBoxTop ||
        objectTop > dragBoxBottom
      );
    }
  });
}

const calculateAngle = (overlayX, overlayY, targetX, targetY, currentZoom) => {
  // Reverse the zoom and pan transformations

  const translatedX = (targetX ) * currentZoom.k + currentZoom.x;
  const translatedY = (targetY ) * currentZoom.k + currentZoom.y;

  // Calculate the angle in radians
  const dx = translatedX - overlayX;
  const dy = translatedY - overlayY;
  const angle = Math.atan2(dy, dx);

  return angle;
};

function calculateControlPoints(x1, y1, x2, y2, pixelsPerPoint = 10, bias = 0.75) {
  // Calculate the Euclidean distance between the two points
  const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

  // Calculate the number of points based on the distance and pixels per point
  let numPoints = Math.floor(distance / pixelsPerPoint);

  // Ensure the number of points is odd, and at least 3 to ensure a midpoint
  if (numPoints % 2 === 0) {
      numPoints += 1;
  }

  if (numPoints < 9) {
      numPoints = 9;
  }

  // Calculate the x-difference between the two points
  let deltaX = Math.abs(x2 - x1);
  let deltaY = y2 - y1;

  if(x2 - x1 < 0){
    deltaX *= 1.5;
  } else {
    deltaY = 0;
  }

  // Calculate control points for the Bézier curve with adjusted deltaX
  const cp1x = x1 + deltaX * bias;
  const cp1y = y1 + deltaY * bias / 2;
  
  const cp2x = x2 - deltaX * bias;
  const cp2y = y2 - deltaY * bias / 2;

  // Array to store the coordinates
  const curvePoints = [];

  // Generate the curve points
  for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;

      // Calculate the x and y coordinates at this point on the curve
      const x = (1 - t) ** 3 * x1 +
                3 * (1 - t) ** 2 * t * cp1x +
                3 * (1 - t) * t ** 2 * cp2x +
                t ** 3 * x2;
      
      const y = (1 - t) ** 3 * y1 +
                3 * (1 - t) ** 2 * t * cp1y +
                3 * (1 - t) * t ** 2 * cp2y +
                t ** 3 * y2;

      // Add the point to the array
      curvePoints.push({ x, y });
  }

  return curvePoints;
}

const calculatePluginDropZoneLocation = (node, nodeTypes = [], nodes, links) => {
  // based on how many links are to this node's plugins inputs, space out the drop zone accordingly
  let allInputLinksToThisNode = links.filter(l => l.to.node_id === node.id);
  let allOutputLinksToThisNode = links.filter(l => l.from.node_id === node.id);

  let thisNodeType = nodeTypes.find(n => n.name === node.type);
  if(!thisNodeType) {
    return {
      x: node.x,
      rel_x: 0,
      y: node.y,
      rel_y: 0,
      width: nodeWidth,
      height: 0
    }
  }

  let hasAdditionalInputs = allInputLinksToThisNode.some(l => {
    // is the to input an additional input?
    let foundInput = thisNodeType.inputs.find(i => i.name === l.to.input);
    if(foundInput){
      return foundInput.additional;
    }
  });

  let hasAdditionalOutputs = allOutputLinksToThisNode.some(l => {
    // is the from output an additional output?
    let foundOutput = thisNodeType.outputs.find(i => i.name === l.from.output);
    if(foundOutput){
      return foundOutput.additional;
    }
  });

  let inputPluginLinks = links.filter(l => l.to.node_id === node.id && l.to.input === 'plugins');

  // create a new array of nodes that are connected to this node
  let connectedNodes = [];
  inputPluginLinks.forEach(l => {
    let foundNode = nodes.find(n => n.id === l.from.node_id);
    if(foundNode){
      connectedNodes.push(foundNode);
    }
  });

  // calculate the height of each connected node type
  let y = 0;
  let rel_y = 0;

  // does the current node have any additional inputs or outputs that are linked and/or is show_additional set to true?
  // if(hasAdditionalInputs || hasAdditionalOutputs || node.show_additional){
  
    y += calculateNodeHeight({type: node.type}, nodeTypes, node.show_additional, hasAdditionalInputs || hasAdditionalOutputs);
  // }

  y += nodePluginSpacing;
  rel_y += nodePluginSpacing;

  connectedNodes.forEach(type => {
    let h = calculateNodeHeight({type: type.type}, nodeTypes);
    y += h;
    y += nodePluginSpacing;

    rel_y += h;
    rel_y += nodePluginSpacing;
  });

  
  return {
    x: node.x,
    rel_x: 0,
    y: node.y + y,
    rel_y: rel_y,
    width: nodeWidth,
    height: nodePluginDropZoneHeight
  }
}


const calculateNodeHeight = (node, nodeTypes = [], includeAdditional) => {
  if(!node) return nodeHeaderHeight + nodePaddingPerPort * 2;
  if(nodeTypes.length === 0) return nodeHeaderHeight + nodePaddingPerPort * 2;

  // lets find this nodeTypes in our nodeTypes array by type and name
  let foundNodeType = nodeTypes.find(n => n.name === node.type);
  let portCount = 0;
  let customHeight = 0;
  let hasAnyAdditional;
  if(foundNodeType){

    let visibleInputs = foundNodeType.inputs.filter(i => !i.hidden);
    let visibleOutputs = foundNodeType.outputs.filter(i => !i.hidden);

    hasAnyAdditional = visibleInputs.some(i => i.additional) || visibleOutputs.some(i => i.additional); 

    let inputPortCount = 0;
    if(includeAdditional){
      inputPortCount = visibleInputs.length;
    } else {
      inputPortCount = visibleInputs.filter(i => !i.additional).length;
    }

    let outputPortCount = 0;
    if(includeAdditional){
      outputPortCount = visibleOutputs.length;
    } else {
      outputPortCount = visibleOutputs.filter(i => !i.additional).length;
    }

    portCount = Math.max(inputPortCount, outputPortCount);

    if(foundNodeType.custom_render_height){
      customHeight = foundNodeType.custom_render_height;
    }
  }

  let height = nodeHeaderHeight + (1 + portCount) * nodePaddingPerPort + customHeight;

  if(hasAnyAdditional){
    // add a bit more for the additional button if it exists
    height += 10;
  }

  return height;
}

const calculateHandleYValue = (node, nodeTypes, handleName, handleSide) => {
  
  // figure out what index this handle is return the correct y value for it relative to the top of the node

  let foundNodeType = nodeTypes.find(n => n.name === node.type);
  let portIndex = 0;
  let customHeight = 0;

  if(foundNodeType){
    portIndex = foundNodeType[handleSide].findIndex(o => o.name === handleName);

    if(foundNodeType.custom_render_height){
      customHeight = foundNodeType.custom_render_height;
    }
  }

  return nodeHeaderHeight + nodePaddingPerPort + portIndex * nodePaddingPerPort + customHeight;
}



const formatLinkId = (link) => {
  return link.from.node_id + ':' + link.from.output + ' -> ' + link.to.node_id + ':' + link.to.input;
}

const unformatLinkId = (linkId) => {
  let parts = linkId.split(' -> ');
  let fromParts = parts[0].split(':');
  let toParts = parts[1].split(':');
  return {
    from: {
      node_id: fromParts[0],
      output: fromParts[1]
    },
    to: {
      node_id: toParts[0],
      input: toParts[1]
    }
  }
}

const findNearestNeighbors = (target, objects) => {
  const { id, x: tx, y: ty, width: tw, height: th } = target;

  let nearestLeft = null;
  let nearestRight = null;
  let nearestTop = null;
  let nearestBottom = null;

  let minDistLeft = Infinity;
  let minDistRight = Infinity;
  let minDistTop = Infinity;
  let minDistBottom = Infinity;

  const euclideanDistance = (x1, y1, x2, y2) => {
      return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  objects.forEach(obj => {
      if (obj.id === id) return;

      const { x, y, width, height } = obj;

      // Calculate Euclidean distances
      const objCenterX = x + width / 2;
      const objCenterY = y + height / 2;
      const targetCenterX = tx + tw / 2;
      const targetCenterY = ty + th / 2;
      const eucDist = euclideanDistance(objCenterX, objCenterY, targetCenterX, targetCenterY);

      // Check left
      const distLeft = tx - (x + width);
      if (distLeft >= 0 && distLeft <= minDistLeft) {
          if (distLeft < minDistLeft || (distLeft === minDistLeft && eucDist < euclideanDistance(nearestLeft?.x + nearestLeft?.width / 2, nearestLeft?.y + nearestLeft?.height / 2, targetCenterX, targetCenterY))) {
              minDistLeft = distLeft;
              nearestLeft = obj;
          }
      }

      // Check right
      const distRight = x - (tx + tw);
      if (distRight >= 0 && distRight <= minDistRight) {
          if (distRight < minDistRight || (distRight === minDistRight && eucDist < euclideanDistance(nearestRight?.x + nearestRight?.width / 2, nearestRight?.y + nearestRight?.height / 2, targetCenterX, targetCenterY))) {
              minDistRight = distRight;
              nearestRight = obj;
          }
      }

      // Check top
      const distTop = ty - (y + height);
      if (distTop >= 0 && distTop <= minDistTop) {
          if (distTop < minDistTop || (distTop === minDistTop && eucDist < euclideanDistance(nearestTop?.x + nearestTop?.width / 2, nearestTop?.y + nearestTop?.height / 2, targetCenterX, targetCenterY))) {
              minDistTop = distTop;
              nearestTop = obj;
          }
      }

      // Check bottom
      const distBottom = y - (ty + th);
      if (distBottom >= 0 && distBottom <= minDistBottom) {
          if (distBottom < minDistBottom || (distBottom === minDistBottom && eucDist < euclideanDistance(nearestBottom?.x + nearestBottom?.width / 2, nearestBottom?.y + nearestBottom?.height / 2, targetCenterX, targetCenterY))) {
              minDistBottom = distBottom;
              nearestBottom = obj;
          }
      }
  });

  // Set to null if they exceed the minimum distance limit
  let minDistanceLimit = 300;

  if (minDistLeft > minDistanceLimit) nearestLeft = null;
  if (minDistRight > minDistanceLimit) nearestRight = null;
  if (minDistTop > minDistanceLimit) nearestTop = null;
  if (minDistBottom > minDistanceLimit) nearestBottom = null;

  return {
      left: nearestLeft,
      right: nearestRight,
      top: nearestTop,
      bottom: nearestBottom
  };
}

export { 
  nodeHeaderHeight,
  nodePaddingPerPort,
  nodeWidth,
  nodePluginSpacing,
  nodePluginDropZoneHeight,
  calculateAngle, 
  calculatePluginDropZoneLocation,
  calculateControlPoints,
  calculateNodeHeight,
  calculateHandleYValue,
  formatLinkId,
  unformatLinkId,
  customRenderDictionary,
  filterNodesByDragBox,
  findNearestNeighbors,
  getHydratedFlowNodeLibrary,
  convertComponentToNode
};