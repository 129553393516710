

const convertMessageCacheToArray = (messageCache = []) => {
  let retval = [];

  messageCache.forEach((event, index) => {

    if(event.request){
      if(event.request.data.messages){

        // grab the most recent message
        let message = event.request.data.messages[event.request.data.messages.length - 1];
        
        let formattedMessage = {
          cacheIndex: index,
          role: message.role,
          content: message.content,
          timestamp: message.timestamp,
          function_responses: message.function_responses,
        }

        retval.push(formattedMessage);
      }
    }
    if(event.response){
      if(event.response.output_data){
        let agentMessage = {
          cacheIndex: index,
          role: event.response.output_data.role,
          content: event.response.output_data.content,
          timestamp: event.response.output_data.timestamp,
          function_calls: event.response.output_data.function_calls,
        }
        retval.push(agentMessage);
      }
    } else if(event.updates && event.updates.length > 0){
      // create an update message out of the most recent update
      let updateMessage = {
        cacheIndex: index,
        role: "agent",
        isInProgress: true,
        content: event.updates[event.updates.length - 1].content || "thinking..."
      }
      retval.push(updateMessage);
    }

  });


  return retval;
}

export default convertMessageCacheToArray;