import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import ReactJson from 'react-json-view';

import './CodeHighlighter.scss'; // Assuming SCSS module usage
import { diff } from 'deep-diff';

const unifiedTheme = {
  base00: '#141B1F', // Default Background
  base01: '#1B262E', // Lighter Background (status bars, line numbers)
  base02: '#1B262E', // Selection Background
  base03: '#8899A6', // Comments, Invisibles, Line Highlighting
  base04: '#788690', // Dark Foreground (status bars)
  base05: '#F8F9FB', // Default Foreground, Caret, Delimiters, Operators
  base06: '#E1E4EA', // Light Foreground (Not often used)
  base07: '#F8F9FB', // Light Background (Not often used)
  base08: '#F94E76', // Variables, XML Tags, Markup Link Text, Diff Deleted
  base09: '#FF4759', // Integers, Boolean, Constants, XML Attributes
  base0A: '#1FB8FF', // Classes, Markup Bold, Search Text Background
  base0B: '#53b882', // Strings, Inherited Class, Markup Code
  base0C: '#71CDFF', // Support, Regular Expressions, Escape Characters
  base0D: '#027CFE', // Functions, Methods, Attribute IDs, Headings
  base0E: '#7851a9', // Keywords, Storage, Selector, Markup Italic
  base0F: '#D24128', // Deprecated, Opening/Closing Embedded Language Tags

  background: '#141B1F', // $black
  text: '#F8F9FB', // $white (adjusted for readability)
  string: 'text-success', // $green
  number: '#FF4759', // $orange
  boolean: 'text-blue', // $blue
  null: '#71CDFF', // $info
  key: '#1FB8FF', // $component
  variable: '#F94E76', // $inquiry
  function: '#027CFE', // $blue
  keyword: '#7851a9', // $purple
  comment: '#8899A6', // $dark-soft
  class: '#1FB8FF', // $component
  method: '#027CFE', // Same as functions
  operator: '#F8F9FB', // $white (adjusted for readability)
  property: '#9CDCFE', // Adjusted for visibility
};

// Assuming a simplified mapping for demonstration purposes
const syntaxHighlighterTheme = {
  "code[class*=\"language-\"]": {
      "color": "#F8F9FB", // Using $white for general text
      "background": "#141B1F", // Using $black for background
      // "fontFamily": "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
      "direction": "ltr",
      "textAlign": "left",
      "whiteSpace": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "lineHeight": "1.5",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
      "color": "#F8F9FB", // Using $white for general text
      "padding": "1em",
      "margin": ".5em 0",
      "overflow": "auto",
      "borderRadius": "0.3em",
      "background": "#0A0E0F" // Using $black for background
  },
  ":not(pre) > code[class*=\"language-\"]": {
      "background": "#0A0E0F", // Using $black for background
      "padding": ".1em",
      "borderRadius": ".3em"
  },
  "comment": {
      "color": "#8899A6", // Using $dark-soft for comments
  },
  "keyword": {
      "color": "#027CFE", // Using $primary for keywords
  },
  "number": {
      "color": "#FF4759", // Using $orange for numbers
  },
  "string": {
      "color": "#53b882", // Using $green for strings
  },
  "function": {
      "color": "#1FB8FF", // Using $blue for functions
  },
  "variable": {
      "color": "#F94E76", // Using $inquiry for variables
  },
  "boolean": {
      "color": "#027CFE", // Using $blue for booleans, adjusted for visibility
  },
};


const CodeHighlighter = React.memo(({ code, codeExamples, inline, language, showCopyButton, showLineNumbers, collapsedJSONDepth }) => {
  
  // Initialize with the first language in codeExamples, or default to an empty string
  const initialLanguage = language || (codeExamples.length > 0 ? (codeExamples[0] || {}).language : '');
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const [copied, setCopied] = useState(false);
  const [collapsedParameters, setCollapsedParameters] = useState({});

  
  const handleChange = (event) => {
    setCurrentLanguage(event.target.value);
  };

  const copyToClipboard = (codeToCopy) => {
    navigator.clipboard.writeText(codeToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const renderCopyButton = (codeToCopy) => {
    return (
      showCopyButton ? (
        <button onClick={() => copyToClipboard(codeToCopy)} className="code-highlighter-copy-button">
          {
            copied ? 
            <i className="far fa-check fa-fw"/>
            :
            <i className="far fa-copy fa-fw"/>
          }
        </button>
      ) : null
    );
  };

  const renderParameter = (parameter, depth) => {
    let children = parameter.parameters || [];

    return (<div className={"code-highlighter-parameter padding-1rem"}>
      <div className="">
        <span className="no-margin-bottom no-margin-top code-highlighter-parameter-name text-900"  onClick={() => setCollapsedParameters({...collapsedParameters, [parameter.name]: !collapsedParameters[parameter.name]})}>
          {
            collapsedParameters[parameter.name] ?
            <i className="fas fa-caret-right text-muted icon-before-text"/>
            :
            <i className="fas fa-caret-down text-muted icon-before-text"/>
          }
          {parameter.name}
        </span>
        &nbsp;&nbsp;
        {
          parameter.type && <small className="code-highlighter-parameter-type text-semi-muted">{parameter.type}</small>
        }
        &nbsp;&nbsp;
        <i>
          {
            parameter.required === true ? <small className="text-danger">required</small>
            :
            parameter.required ?
            <small className="text-danger">{parameter.required}</small>
            :
            <small className="text-warning">optional</small>
          }
        </i>
      </div>
      {
        !collapsedParameters[parameter.name] && <div className="code-highlighter-parameter-description">{parameter.description}</div>
      }
      {
        (!collapsedParameters[parameter.name] && children && children.length > 0) && renderParameters(children, depth ? depth + 1 : 1)
      }
    </div>);
  }

  const renderParameters = (parameters, depth) => {

    return <div className={"box box-no-pad margin-bottom-2rem code-highlighter-parameters box-light-border " + (depth ? "box-no-shadow " : "") }>
      {
        parameters && parameters.map((parameter, index) => {
          return renderParameter(parameter, depth ? depth + 1 : 1);
        })
      }
    </div>
  }

  const renderCode = () => {
    let codeToRender = code;
    let languageToUse = currentLanguage;

    if (codeExamples?.length) {
      const currentCodeExample = codeExamples.find(example => example.language === currentLanguage)?.code || '';
      codeToRender = currentCodeExample;
    }

    // If language is not set and codeExamples is not provided, try auto-detecting
    if (!languageToUse && !codeExamples) {
      languageToUse = 'plaintext'; // Fallback to plaintext if auto-detection is not reliable
    }

    if(languageToUse === 'parameter' && codeToRender) {
      // split the code into lines and key-value pair using ": " to split and create a new object
      const data = JSON.parse(codeToRender[0]);
      return renderParameters(data);
    }

    let parsedCode = {};
    
    try {
      parsedCode = JSON.parse(codeToRender);

      if(typeof parsedCode === 'object' && !Array.isArray(parsedCode)){
        languageToUse = 'json';
      } else {
        // default to javascript
        languageToUse = 'javascript';
      }
    } catch (e) {
      // default to javascript
      parsedCode = codeToRender;
      languageToUse = 'javascript';
    }
    
    return (
      <div className="code-highlighter-container">
        {renderCopyButton(codeToRender)}
        {languageToUse === 'json' ? (
          <ReactJson 
            iconStyle='triangle'
            name={false}
            displayDataTypes={false}
            sortKeys={true}
            src={parsedCode} 
            enableClipboard={false}
            displayObjectSize={false}
            theme={unifiedTheme}
            collapsed={collapsedJSONDepth}
            />
        ) : (
          <SyntaxHighlighter language={languageToUse} style={syntaxHighlighterTheme} showLineNumbers={showLineNumbers}>
            {codeToRender}
          </SyntaxHighlighter>
        )}
      </div>
    );
  };

  return (
    <div className="code-highlighter">
      {codeExamples.length > 0 && (
        <div>
          <select onChange={handleChange} value={currentLanguage}>
            {codeExamples.map(({ language }) => (
              <option key={language} value={language}>{language.toUpperCase()}</option>
            ))}
          </select>
        </div>
      )}
      {inline ? (
        <code className="code-highlighter-inline">{renderCode()}</code>
      ) : (
        currentLanguage === "parameter" ?
        renderCode()
        :
        <pre className="code-highlighter-block">{renderCode()}</pre>
      )}
    </div>
  );
}, (prevProps, nextProps) => {
  if(diff(prevProps, nextProps) === undefined) {
    return true;
  }
  return false;
});

CodeHighlighter.propTypes = {
  code: PropTypes.string,
  codeExamples: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
  inline: PropTypes.bool,
  language: PropTypes.string,
  showCopyButton: PropTypes.bool,
  showLineNumbers: PropTypes.bool,
  collapsedJSONDepth: PropTypes.number,
};

CodeHighlighter.defaultProps = {
  code: '',
  codeExamples: [],
  inline: false,
  language: false,
  showCopyButton: true,
  showLineNumbers: false,
};

export default CodeHighlighter;
