import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'

export const NodeSettings_slider = ({ node, setNodeSettings, canWrite }) => {

  if (!node.settings) {
    return null;
  }

  return (
    <div>
      <CustomField
        value={node.settings.min}
        label="Minimum Value"
        type="number"
        disabled={!canWrite}
        placeholder="Enter the minimum value here"
        onChange={(e)=>{
          setNodeSettings({
            ...node.settings,
            min: parseFloat(e.value)
          })
        }}
        />
      <CustomField
        value={node.settings.max}
        label="Maximum Value"
        type="number"
        disabled={!canWrite}
        placeholder="Enter the maximum value here"
        onChange={(e)=>{
          setNodeSettings({
            ...node.settings,
            max: parseFloat(e.value)
          })
        }}
        />
      
      <CustomField
        value={node.settings.step}
        label="Step Value"
        type="number"
        disabled={!canWrite}
        placeholder="Enter the step value here"
        onChange={(e)=>{
          setNodeSettings({
            ...node.settings,
            step: parseFloat(e.value)
          })
        }}
        />
      
    </div>
  );
}


export const NodeRenderer_slider = ({ node, type, interactive, setNodeSettings, canWrite }) => {
  

  const [value, setValue] = useState(node.settings.value || 0);

  useEffect(() => {
    setValue(node.settings.value || 0);
  }, [node.settings.value]);
  
  let updateTimeout = null;

  return (
    <div className="padding-1rem thin-line-height flex-split no-select">
      <div className="flex-grow">
        <input 
          type="range" 
          style={{width: "100%"}}
          min={node.settings.min === undefined ? 0 : node.settings.min}
          max={node.settings.max === undefined ? 100 : node.settings.max}
          step={node.settings.step === undefined ? 1 : node.settings.step}
          disabled={!interactive || !canWrite} 
          value={value || 0}
          onBlur={() => {
            // setNodeSettings(node.id, {
            //   value: parseFloat(value)
            // });
          }}
          onChange={e => {
            setValue(e.target.value);
            if(updateTimeout){
              clearTimeout(updateTimeout);
            }
            updateTimeout = setTimeout(() => {
              setNodeSettings(node.id, {
                value: parseFloat(e.target.value)
              });
            }, 100);
          }}
          />
      </div>

      <span className="margin-left-1rem">
        {
          value.toLocaleString()
        }
      </span>
    </div>
  );
}