export * from './components/a.components.POST';
export * from './components/a.components.POST.copy.id.version';
export * from './components/a.components.GET.id';
export * from './components/a.components.GET.id.version';
export * from './components/a.components.GET.id.version.type';
export * from './components/a.components.GET.demo.id.version';

export * from './components/a.components.GET.by-organization';
export * from './components/a.components.GET.by-org-mine';
export * from './components/a.components.PUT.settings';
export * from './components/a.components.PUT.draft.model';
export * from './components/a.components.PUT.draft.flow';
export * from './components/a.components.PUT.draft.context-options';
export * from './components/a.components.PUT.draft.memory-options';
export * from './components/a.components.POST.draft.content';
export * from './components/a.components.PUT.draft.content';
export * from './components/a.components.PUT.draft.content.restore';
export * from './components/a.components.DELETE.draft.content';
export * from './components/a.components.POST.publish';
export * from './components/a.components.POST.id.version.download';

export * from './components/a.components.POST.demo.id.version';
export * from './components/a.components.POST.demo.auth.id.version';
export * from './components/a.components.POST.test.id.version';

export * from './components/a.components.PUT.draft.content.order';

export * from './components/a.components.DELETE.id';

export * from './components/a.components.POST.explain-nodes.id';
export * from './components/a.components.POST.generate-flow.id';
export * from './components/a.components.GET.public.id';

export const setCurrentComponent = data => ({
  type: 'SET_CURRENT_COMPONENT',
  data
})

export const unsetCurrentComponent = data => ({
  type: 'UNSET_CURRENT_COMPONENT',
  data
})

export const passProfileComponentsToCache = data => ({
  type: 'PASS_PROFILE_COMPONENTS_TO_CACHE',
  data
})


export const addToComponentUndoHistory = data => ({
  type: 'ADD_TO_COMPONENT_UNDO_HISTORY',
  data
})

export const moveComponentUndoHistoryIndex = data => ({
  type: 'MOVE_COMPONENT_UNDO_HISTORY_INDEX',
  data
})

export const clearComponentUndoHistory = data => ({
  type: 'CLEAR_COMPONENT_UNDO_HISTORY',
  data
})


export const bulkUpdateComponentFromSocket = data => ({
  type: 'BULK_UPDATE_COMPONENT_FROM_SOCKET',
  data
})

export const bulkUpdateComponent = data => ({
  type: 'BULK_UPDATE_COMPONENT',
  data
})

export const undoBulkUpdateComponent = data => ({
  type: 'UNDO_BULK_UPDATE_COMPONENT',
  data
})

export const redoBulkUpdateComponent = data => ({
  type: 'REDO_BULK_UPDATE_COMPONENT',
  data
})

export const setComponentNeedsSave = data => ({
  type: 'SET_COMPONENT_NEEDS_SAVE',
  data
})

export const clearLinkCache = data => ({
  type: 'CLEAR_LINK_CACHE',
  data
})

export const clearTempFlow = data => ({
  type: 'CLEAR_TEMP_FLOW',
  data
})

export const stopDemoComponent = data => ({
  type: 'STOP_DEMO_COMPONENT',
  data
})

export const removeDemoCacheItem = data => ({
  type: 'REMOVE_DEMO_CACHE_ITEM',
  data
})

export const removeDemoCacheAfterIndex = data => ({
  type: 'REMOVE_DEMO_CACHE_AFTER_INDEX',
  data
})