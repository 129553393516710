import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';



export const NodeSettings_subflow = ({ node, setNodeSettings, canWrite }) => {

  // load up the component list from this 

  if (!node.settings) {
    return null;
  }

  const [settings, setSettings] = useState(node.settings || {});

  return (
    <div>
      <CustomField
        label="Text"
        value={settings.component_id}
        canWrite={canWrite}
        disabled={!canWrite}
        placeholder="Paste an agent flow ID here"
        description="You can find the agent flow ID in the URL of the agent flow editor."
        onChange={(e) => {
          setSettings({
            ...settings,
            component_id: e.value,
          })
        }}
        onBlur={(e) => {
          setNodeSettings(settings)
        }}
        />
      <CustomSelect
        label="Version"
        value={settings.version}
        canWrite={canWrite}
        clearable={true}
        disabled={!canWrite}
        options={[
          {
            label: "Current draft",
            value: "draft"
          },
          {
            label: "Latest published",
            value: "current_version"
          }
        ]}
        onChange={(e) => {
          setSettings({
            ...settings,
            version: e,
          })
        }}/>

    </div>
  );
}


// export const NodeRenderer_subflow = ({ node, type }) => {

//   let role = node.settings.role;
//   if(!role) {
//     role = type.settings.find(s => s.name === 'role').default;
//   }
  
//   return (
//     <div className="">
//       <div className="padding-1rem thin-line-height">
//         {
//           role === 'system' &&
//           <div className="text-900">
//             <i className="fas fa-chalkboard-teacher fa-fw icon-before-text"/> Instruction
//           </div>
//         }
//         {
//           role === 'agent' &&
//           <div className="text-900">
//             <i className="fas fa-robot fa-fw icon-before-text"/> Agent
//           </div>
//         }
//         {
//           role === 'user' &&
//           <div className="text-900">
//             <i className="fas fa-user fa-fw icon-before-text"/> User
//           </div>
//         }
//         {
//           role === 'assistant' &&
//           <div className="text-900">
//             <i className="fas fa-robot fa-fw icon-before-text"/> Assistant (legacy)
//           </div>
//         }
//       </div>
//       <div className="scroll-parent" style={{width: 250, height: 200}}>
//         <div className="scroll-child padding-1rem thin-line-height">
//           {/* <small>{node.settings.text}</small> */}
//           <MegaMarkdown text={node.settings.text} />
//         </div>
//       </div>
//     </div>
//   );
// }