import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect'

export const NodeSettings_openai_chat = ({ node, setNodeSettings, canWrite }) => {

  const [settings, setSettings] = useState(node.settings || {});

  const intelReducer = useSelector(state => state.intelReducer);

  return (
    <div>
      <CustomSelect
        value={settings.model}
        label="Which foundational LLM would you like to build upon?"
        placeholder="GPT-4, GPT-4o, etc"
        description="Which model should this agent use?"
        inline={true}
        large={true}
        required={true}
        disabled={!canWrite}
        options={intelReducer.llms.filter(m => m.showForTypes?.includes('chat') && m.provider === 'OpenAI').map((model, i)=>{
          return {
            label: <div className="thin-line-height list-left">
              {model.provider === 'OpenAI' && <img src="/img/openai_logo_small.png" width="30"/>}
              {model.provider === 'Google PaLM2' && <img src="/img/palm2_logo_small.png" width="30"/>}
              {model.provider === 'Google Gemini' && <img src="/img/gemini_logo_small.png" width="30"/>}
              {model.provider === "Anthropic" && <img src="/img/anthropic_logo_small.png" width="30"/>}
              {model.provider === "Meta" && <img src="/img/meta_logo_small.png" width="30"/>}
              <div>
                <strong>{model.name}</strong><br/>
                <div className="text-ellipsis-1-lines"><small>by {model.provider}  {model.legacy ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-danger">LEGACY</span> : model.preview ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-warning">PREVIEW</span> : ""}</small></div>
              </div>
            </div>,
            value: model.name
          }
        })}
        onChange={(e) => {

          let parameters = settings.parameters;
          let newModel = intelReducer.llms.find(model => model.name === e);
          if(newModel.parameters){
            parameters = {};
            for(var i in newModel.parameters){
              parameters[newModel.parameters[i].name] = newModel.parameters[i].default;
            }
          }

          setNodeSettings({
            model: e,
            parameters: parameters
          })
        }}
        />
    </div>
  );
}


export const NodeRenderer_openai_chat = ({ node }) => {
  
  return (
    <div>
      <div>
        <strong>LLM</strong>
      </div>
      <div>
        <small>test</small>
      </div>
    </div>
  );
}