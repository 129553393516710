import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  tryToCopyComponent
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ComponentVersionBar from 'components/ComponentVersionBar/ComponentVersionBar.js'
import WorkbenchRecentComponentDynamic from 'components/Workbench/WorkbenchRecentComponentDynamic.js'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'

import tools from 'configs/config.component-tools'

import './ComponentVersion.scss';
import Callout from 'kit/components/Callout/Callout'
import FlowRenderer from 'components/FlowBuilder/FlowRenderer'


class ComponentVersion extends Component {
  constructor(props){
    super(props);

  }

  render(){

    const { dispatch, orgReducer, componentReducer, userReducer, intelReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(!component) return <span>component failed to load.</span>;

    return <div className="row">
      <div className="col-md-12">
        <ComponentVersionBar
          id={component.id}
          />
      </div>
      {
        // component.current_version === 'draft' &&
        // <div className="col-md-12">
        //   <Callout
        //     style="warning"
        //     title="This flow hasn't been published yet."
        //     content="You can still work on it, but it won't be visible beyond your organization or available via the API until you publish it."
        //     />
        // </div>
      }
      {
        component.versions.map((v, i) => {
          return <div className="col-md-12 margin-bottom-2rem" key={i}>
            <div className="box">
              <div className="row">
                <div className="col-md-8">
                  <div className="flex-split flex-split-align-start">
                    <div>
                      <div className="list-left">
                        <h4 className="no-margin">{v.id}</h4>
                      </div>
                      <small className="text-muted">
                          Created {moment(v.created_at).format('MMMM Do YYYY, h:mm a')}
                      </small>
                    </div>
                    <div className="list-right">
                      <CustomButton
                        display={<span><small className="far fa-copy icon-before-text"/>Make a Copy</span>}
                        color="grey"
                        size="xs"
                        thinking={componentReducer.tryingToCopyComponent}
                        fail={componentReducer.copyComponentFail}
                        success={componentReducer.copyComponentSuccess}
                        onClick={() => dispatch(tryToCopyComponent({
                          id: component.id,
                          version: v.id
                        }))}
                        />
                      <CustomButton
                        display={<span><small className="fas fa-play icon-before-text"/>Open Demo Page</span>}
                        color="component"
                        size="xs"
                        toBlank={"/c/demo/" + component.id + "/" + v.id}
                        />
                    </div>
                  </div>

                </div>
                <div className="col-md-4">
                  <div className="sixteen-by-nine-parent">
                    
                    <div className="sixteen-by-nine-child">
                      <FlowRenderer
                        nodes={intelReducer.flow_nodes.length > 0 ? v.flow_nodes : []}
                        links={intelReducer.flow_nodes.length > 0 ? v.flow_links : []}
                        interactive={false}
                        simpleMode={true}
                        showMinimap={false}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })
      }

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, intelReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer,
    intelReducer
  }
}

export default connect(mapStateToProps)(ComponentVersion);

  