import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { tryToDemoComponent, removeDemoCacheItem, showTooltip, hideTooltip } from 'actions/actions.export';
import MegaMarkdown from '../MegaMarkdown/MegaMarkdown.js';
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter.js';

import CustomField from 'kit/components/CustomField/CustomField.js';
import CustomButton from 'kit/components/CustomButton/CustomButton.js';
import Modal from 'kit/components/Modal/Modal.js';

import './BetterPrompt.css';

class BetterPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: shortid.generate(),
      prompt: "",
      lastResponseTimestamp: new Date().toISOString(),
      showBackOfHouse: false,
      showBackOfHouseData: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    // Update state if there's a new response in the demoCache
    if (nextProps.demoCache && nextProps.demoCache.length > 0) {
      let lastResponse = nextProps.demoCache[nextProps.demoCache.length - 1].response;

      if (lastResponse && lastResponse.output_data && lastResponse.output_data.timestamp > this.state.lastResponseTimestamp) {
        this.setState({ lastResponseTimestamp: lastResponse.output_data.timestamp });
      }
    }
  }

  handleSendPrompt = () => {
    // Dispatch the prompt as a Redux action
    this.props.dispatch(tryToDemoComponent({
      id: this.props.component_id,
      version: this.props.version,
      data: {
        data: {
          prompt: this.state.prompt,
          variables: {
            ...this.props.variables
          }
        }
      }
    }, true, this.props.publicDemo));

    // Clear the input
    // this.setState({ prompt: "" });
  }

  handleCopyResponse = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    }
    this.props.dispatch(hideTooltip());
  }

  handleRegenerateResponse = () => {
    // Remove the existing response from the Redux store
    this.props.dispatch(removeDemoCacheItem({
      id: this.props.component_id,
      version: this.props.version,
      index: this.props.demoCache.length - 1,
      whichHalf: "response"
    }));

    // Resend the request with the same prompt
    this.handleSendPrompt();
  }

  render() {

    const { component } = this.props;

    if(!component) return;
    
    // Get the last response from the Redux store
    let response = null;
    let lastResponse;
    if (this.props.demoCache && this.props.demoCache.length > 0) {
      lastResponse = this.props.demoCache[this.props.demoCache.length - 1].response;
      if (lastResponse && lastResponse.output_data) {
        response = lastResponse.output_data.content;
      }
    }
    

    return (
      <div className="better-prompt">

        <Modal
          show={this.state.showBackOfHouse}
          maxWidth={600}
          exitable={true}
          hideExitButton={true}
          onExit={() => this.setState({showBackOfHouse: false})}
          content={<div>
            <CodeHighlighter
              language="json"
              code={JSON.stringify(this.state.showBackOfHouseData, null, 2)}
              collapsedJSONDepth={2}
              />
          </div>}
          />

        {/* Prompt Section */}
        <CustomField
          inline={true}
          value={this.state.prompt}
          onChange={e => this.setState({ prompt: e.value })}
          placeholder={component.demo_placeholder_text || "Send this agent a prompt..."}
          rows={3}
        />
        <div className="spacer-1rem" />

        {/* Submit Button */}
        <CustomButton
          display={component.demo_button_text || <span><i className="fas fa-play icon-before-text" /> Run Flow</span>}
          color="primary"
          thinking={this.props.componentReducer.tryingToDemoComponent}
          block={true}
          onClick={this.handleSendPrompt}
        />

        {/* Response Section */}
        <div className="margin-bottom-05rem margin-top-2rem flex-split">
          <span/>
          <div className={"better-prompt-actions " + (response ? "" : " no-pointer-events text-muted")}>
            <i
              className="fal fa-copy fa-fw"
              onClick={() => this.handleCopyResponse(response)}
              onMouseEnter={(e) =>
                this.props.dispatch(showTooltip({
                  el: e.target,
                  lag: 250,
                  content: <span>Copy Text</span>,
                  position: 'bottom'
                }))
              }
              onMouseLeave={() => this.props.dispatch(hideTooltip())}
            />

            <i
              className="fal fa-trash fa-fw"
              onClick={() => this.props.dispatch(removeDemoCacheItem({
                id: this.props.component_id,
                version: this.props.version,
                index: this.props.demoCache.length - 1,
                whichHalf: "response"
              }))}
              onMouseEnter={(e) =>
                this.props.dispatch(showTooltip({
                  el: e.target,
                  lag: 250,
                  content: <span>Clear Response</span>,
                  position: 'bottom'
                }))
              }
              onMouseLeave={() => this.props.dispatch(hideTooltip())}
            />
            
            <i
              className="fal fa-sync fa-fw"
              onClick={this.handleRegenerateResponse}
              onMouseEnter={(e) =>
                this.props.dispatch(showTooltip({
                  el: e.target,
                  lag: 250,
                  content: <span>Regenerate</span>,
                  position: 'bottom'
                }))
              }
              onMouseLeave={() => this.props.dispatch(hideTooltip())}
            />

            <i className="fal fa-code fa-fw"
              onClick={e => {
                this.setState({
                  showBackOfHouse: true,
                  showBackOfHouseData: lastResponse
                });
              }}
              onMouseEnter={(e) =>
                this.props.dispatch(showTooltip({
                  el: e.target,
                  lag: 250,
                  content: <span>View API Packet</span>,
                  position: 'bottom'
                }))
              }
              onMouseLeave={() => this.props.dispatch(hideTooltip())}
              />
          </div>
        
        </div>
        <div className="box margin-bottom-05rem box-half-pad box-light-border box-no-shadow" style={{ minHeight: 230 }}>
          {response ? (
            <div>
              {(response && response !== null && response.length > 0 && typeof response === "string") && 
                <MegaMarkdown text={response} />
              }
              {
                (response && response !== null && response.length > 0 && Array.isArray(response)) &&
                response.map((content, contentIndex) => (

                  <div key={contentIndex} className="better-chat-message-content-item">
                    {
                      content.type === "text" &&
                      <MegaMarkdown text={content.text} />
                    }
                    {
                      content.type === "function_call" && 
                      <div className="box box-light-border better-chat-message-function-call">
                        <div className="better-chat-message-function-call-title margin-bottom-1rem">
                          <h5 className="no-margin"><code>{content.name}()</code></h5>
                        </div>
                        <hr/>
                        <div className="better-chat-message-function-call-content">
                          <div className="margin-bottom-05rem">Arguments</div>
                          <CodeHighlighter
                            language="json"
                            code={JSON.stringify(content.arguments, null, 2)}
                            collapsedJSONDepth={2}
                            />
                        </div>
                      </div>
                      
                    }

                    {
                      content.type === "image" && <div>
                        {
                          content.source && content.source.url &&
                          <img src={content.source.url} alt={"Image from Message"} />
                        }
                        {
                          content.source && content.source.data &&
                          <img src={content.source.data} alt={"Image from Message"} />
                        }
                      </div>
                    }
                  </div>
                ))
              }
            </div>
          ) : (
            component.demo_greeting ?
            <div className="text-center"><MegaMarkdown text={component.demo_greeting} /></div>
            :
            <span className="text-muted">Waiting for response...</span>
          )}

          
        </div>

        {/* AI Disclaimer */}
        {this.props.publicDemo && (
          <div className="list-right">
            <small className="text-muted">
              AI can make mistakes, verify responses. Do not share sensitive information.
            </small>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { componentReducer } = state;

  return {
    componentReducer
  };
};

export default connect(mapStateToProps)(BetterPrompt);
