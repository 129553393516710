const flow_templates = [
  {
    name: 'blank',
    display_name: 'Blank',
    description: 'Start from scratch with a blank canvas.',
    flow_links: [],
    flow_nodes: []
  },
  {
    name: 'vanilla',
    display_name: 'Vanilla LLM',
    description: 'Essentially a pass-through use of an LLM.',
    flow_links: [
      {
          "from": {
              "node_id": "9Bv5DkI4o",
              "output": "message"
          },
          "to": {
              "node_id": "d6Iu1RCXY",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_messages"
          },
          "to": {
              "node_id": "9Bv5DkI4o",
              "input": "messages"
          }
      }
    ],
    flow_nodes: [
      {
          "id": "3RkDHdFxN",
          "type": "request",
          "x": -426.9209569818007,
          "y": 274.8884696960449,
          "settings": {}
      },
      {
          "id": "d6Iu1RCXY",
          "type": "response",
          "x": 169.93377842823213,
          "y": 274.8884696960449,
          "settings": {}
      },
      {
          "id": "9Bv5DkI4o",
          "type": "openai_gpt_4o_mini",
          "x": -128.4935892767843,
          "y": 274.8884696960449,
          "settings": {}
      }
  ]
  },
  {
    name: 'contextual_chat',
    display_name: 'Contextual Chat',
    description: 'Create a chatbot that can be connected to the context of its conversation.',
    flow_links: [
      {
          "from": {
              "node_id": "NP6Zhg6U9",
              "output": "message"
          },
          "to": {
              "node_id": "d6Iu1RCXY",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_variables"
          },
          "to": {
              "node_id": "0Xi0g5WZF",
              "input": "variables"
          }
      },
      {
          "from": {
              "node_id": "0Xi0g5WZF",
              "output": "prompt"
          },
          "to": {
              "node_id": "NP6Zhg6U9",
              "input": "instructions"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_messages"
          },
          "to": {
              "node_id": "NP6Zhg6U9",
              "input": "messages"
          }
      }
    ],
    flow_nodes: [
      {
          "id": "3RkDHdFxN",
          "type": "request",
          "x": -489.0082961376445,
          "y": 207.39597782294945,
          "settings": {},
          "height": 124,
          "width": 250,
          "cx": -364.0082961376445,
          "cy": 269.3959778229495
      },
      {
          "id": "d6Iu1RCXY",
          "type": "response",
          "x": 509.97994458384125,
          "y": 201.39597782294948,
          "settings": {}
      },
      {
          "id": "NP6Zhg6U9",
          "type": "openai_gpt_4o_mini",
          "x": 200.99201189338925,
          "y": 207.39597782294945,
          "settings": {},
          "height": 112,
          "width": 250,
          "cx": 325.99201189338925,
          "cy": 263.3959778229495
      },
      {
          "id": "0Xi0g5WZF",
          "type": "prompt",
          "x": -139.44854108737562,
          "y": -87.88507658393232,
          "settings": {
              "role": "system",
              "text": "You are a helpful assistant, currently speaking with ${NAME}"
          },
          "height": 316,
          "width": 250,
          "cx": -14.448541087375617,
          "cy": 70.11492341606768
      }
    ]
  },
  {
    name: 'Simple Q&A',
    display_name: 'Simple Q&A',
    description: 'Quickly and simply generate responses to questions, powered by your knowledge.',
    flow_links: [
      {
          "from": {
              "node_id": "d9jbJsDwY",
              "output": "value"
          },
          "to": {
              "node_id": "lzWm056Ga",
              "input": "chunks_to_return"
          }
      },
      {
          "from": {
              "node_id": "gVec43pNI",
              "output": "value"
          },
          "to": {
              "node_id": "lzWm056Ga",
              "input": "embedding_similarity_threshold"
          }
      },
      {
          "from": {
              "node_id": "lzWm056Ga",
              "output": "knowledge_object"
          },
          "to": {
              "node_id": "keE1APRd8",
              "input": "variables"
          }
      },
      {
          "from": {
              "node_id": "keE1APRd8",
              "output": "prompt"
          },
          "to": {
              "node_id": "4iOFfj14F",
              "input": "instructions"
          }
      },
      {
          "from": {
              "node_id": "4iOFfj14F",
              "output": "message"
          },
          "to": {
              "node_id": "d6Iu1RCXY",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "biGOgDeyE",
              "output": "request_prompt"
          },
          "to": {
              "node_id": "4iOFfj14F",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "biGOgDeyE",
              "output": "request_prompt"
          },
          "to": {
              "node_id": "lzWm056Ga",
              "input": "text"
          }
      }
  ],
    flow_nodes: [
      {
          "id": "d6Iu1RCXY",
          "type": "response",
          "x": 1075.6048159015677,
          "y": 364.5490004574645,
          "settings": {}
      },
      {
          "id": "lzWm056Ga",
          "type": "semantic_search",
          "x": -38.45791904627603,
          "y": 473.53770812269676,
          "settings": {
              
          }
      },
      {
          "id": "d9jbJsDwY",
          "type": "slider",
          "x": -558.0375758566009,
          "y": 530.6680919910473,
          "settings": {
              "value": 5,
              "min": 1,
              "max": 10,
              "step": 1
          }
      },
      {
          "id": "gVec43pNI",
          "type": "slider",
          "x": -558.0375758566009,
          "y": 673.0211117054029,
          "settings": {
              "value": 0,
              "min": 0,
              "max": 1,
              "step": 0.05
          }
      },
      {
          "id": "keE1APRd8",
          "type": "prompt",
          "x": 373.25382866617963,
          "y": 98.7496098513348,
          "settings": {
              "role": "system",
              "text": "Here is some information that can help you answer the user's query:\n\n${KNOWLEDGE}\n\nYou are speaking to user in a single-question and single-answer format, not a conversational interface or chat. Write your answer cohesively."
          }
      },
      {
          "id": "4iOFfj14F",
          "type": "openai_gpt_4o_mini",
          "x": 775.5819108235628,
          "y": 364.5490004574645,
          "settings": {},
          "show_additional": true
      },
      {
          "id": "biGOgDeyE",
          "type": "request_single_prompt",
          "x": -558.0375758566009,
          "y": 353.9709630746009,
          "settings": {}
      }
  ]
  },
  {
    name: 'DIY Function Handling',
    display_name: 'DIY Function Handling',
    description: 'Manually create and handle a function call in your flow with a custom response.',
    flow_links: [
      {
          "from": {
              "node_id": "Tf-z9pyq6",
              "output": "request_messages"
          },
          "to": {
              "node_id": "pGKVsI637",
              "input": "m2"
          }
      },
      {
          "from": {
              "node_id": "d7r1RwAs7",
              "output": "text"
          },
          "to": {
              "node_id": "kT62A5tq2",
              "input": "jsonString"
          }
      },
      {
          "from": {
              "node_id": "kT62A5tq2",
              "output": "object"
          },
          "to": {
              "node_id": "-_eDZpLC_",
              "input": "object"
          }
      },
      {
          "from": {
              "node_id": "d4XC7mqJJ",
              "output": "text"
          },
          "to": {
              "node_id": "mzBteT-y4",
              "input": "name"
          }
      },
      {
          "from": {
              "node_id": "-_eDZpLC_",
              "output": "jsonSchema"
          },
          "to": {
              "node_id": "mzBteT-y4",
              "input": "parameters"
          }
      },
      {
          "from": {
              "node_id": "PajIbIo5J",
              "output": "value"
          },
          "to": {
              "node_id": "J1hxLpRJ0",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "2brKSzlbI",
              "output": "messages"
          },
          "to": {
              "node_id": "QVmU8v4ev",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "QVmU8v4ev",
              "output": "output"
          },
          "to": {
              "node_id": "pGKVsI637",
              "input": "m7"
          }
      },
      {
          "from": {
              "node_id": "KwG7Zp_Ao",
              "output": "output"
          },
          "to": {
              "node_id": "xgl-0BMqD",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "xgl-0BMqD",
              "output": "output"
          },
          "to": {
              "node_id": "peEgi3-fE",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "2MxQSI-0D",
              "output": "text"
          },
          "to": {
              "node_id": "uGPzpxQme",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "X3VCdZvY8",
              "output": "prompt"
          },
          "to": {
              "node_id": "pGKVsI637",
              "input": "m1"
          }
      },
      {
          "from": {
              "node_id": "oZR3rFvlw",
              "output": "object"
          },
          "to": {
              "node_id": "peEgi3-fE",
              "input": "metadata"
          }
      },
      {
          "from": {
              "node_id": "pGKVsI637",
              "output": "messages"
          },
          "to": {
              "node_id": "tdBeiI42P",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "tdBeiI42P",
              "output": "message"
          },
          "to": {
              "node_id": "2brKSzlbI",
              "input": "m1"
          }
      },
      {
          "from": {
              "node_id": "mzBteT-y4",
              "output": "function_declaration"
          },
          "to": {
              "node_id": "tdBeiI42P",
              "input": "functions"
          }
      },
      {
          "from": {
              "node_id": "tdBeiI42P",
              "output": "content"
          },
          "to": {
              "node_id": "xgl-0BMqD",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "uGPzpxQme",
              "output": "output"
          },
          "to": {
              "node_id": "PajIbIo5J",
              "input": "key"
          }
      },
      {
          "from": {
              "node_id": "mzBteT-y4",
              "output": "function_declaration"
          },
          "to": {
              "node_id": "luU-rxf81",
              "input": "function_declaration"
          }
      },
      {
          "from": {
              "node_id": "tdBeiI42P",
              "output": "message"
          },
          "to": {
              "node_id": "luU-rxf81",
              "input": "message"
          }
      },
      {
          "from": {
              "node_id": "luU-rxf81",
              "output": "match"
          },
          "to": {
              "node_id": "uGPzpxQme",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "luU-rxf81",
              "output": "match"
          },
          "to": {
              "node_id": "KwG7Zp_Ao",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "luU-rxf81",
              "output": "match"
          },
          "to": {
              "node_id": "oZR3rFvlw",
              "input": "value"
          }
      },
      {
          "from": {
              "node_id": "luU-rxf81",
              "output": "match"
          },
          "to": {
              "node_id": "QVmU8v4ev",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "luU-rxf81",
              "output": "arguments"
          },
          "to": {
              "node_id": "PajIbIo5J",
              "input": "object"
          }
      },
      {
          "from": {
              "node_id": "d4XC7mqJJ",
              "output": "text"
          },
          "to": {
              "node_id": "DDy8sIN53",
              "input": "name"
          }
      },
      {
          "from": {
              "node_id": "wgFJf6BSS",
              "output": "text"
          },
          "to": {
              "node_id": "mzBteT-y4",
              "input": "description"
          }
      },
      {
          "from": {
              "node_id": "J1hxLpRJ0",
              "output": "knowledge_object"
          },
          "to": {
              "node_id": "DDy8sIN53",
              "input": "response"
          }
      },
      {
          "from": {
              "node_id": "DDy8sIN53",
              "output": "message"
          },
          "to": {
              "node_id": "2brKSzlbI",
              "input": "m2"
          }
      }
  ],
    flow_nodes: [
      {
          "id": "peEgi3-fE",
          "type": "response",
          "x": 2336.080265013721,
          "y": -420.5969299189697,
          "settings": {},
          "height": 124,
          "width": 250,
          "cx": 2461.080265013721,
          "cy": -358.5969299189697
      },
      {
          "id": "Tf-z9pyq6",
          "type": "request",
          "x": -395,
          "y": -276.2246563561269,
          "settings": {},
          "height": 124,
          "width": 250,
          "cx": -270,
          "cy": -214.2246563561269
      },
      {
          "id": "pGKVsI637",
          "type": "messages",
          "x": 84.00600218381797,
          "y": -427.6747818591487,
          "settings": {},
          "height": 234,
          "width": 250,
          "cx": 209.00600218381797,
          "cy": -310.6747818591487
      },
      {
          "id": "d4XC7mqJJ",
          "type": "text",
          "x": -395,
          "y": 117.61764473542607,
          "settings": {
              "text": "searchKnowledge"
          }
      },
      {
          "id": "wgFJf6BSS",
          "type": "text",
          "x": -395,
          "y": 426.04031613989287,
          "settings": {
              "text": "TODO: Describe the when and why the LLM should choose to query this Knowledge Base"
          },
          "height": 280,
          "width": 250,
          "cx": -270,
          "cy": 566.0403161398929
      },
      {
          "id": "d7r1RwAs7",
          "type": "text",
          "x": -395,
          "y": 725.637171477812,
          "settings": {
              "text": "{ \"search_text\": \"test\"}"
          },
          "height": 280,
          "width": 250,
          "cx": -270,
          "cy": 865.637171477812
      },
      {
          "id": "kT62A5tq2",
          "type": "json_parse",
          "x": -88.02474916615618,
          "y": 923.420942916491,
          "settings": {},
          "height": 80,
          "width": 250,
          "cx": 36.975250833843816,
          "cy": 963.420942916491
      },
      {
          "id": "-_eDZpLC_",
          "type": "json_schema_generator",
          "x": 215.43035112941016,
          "y": 923.420942916491,
          "settings": {},
          "height": 80,
          "width": 250,
          "cx": 340.43035112941016,
          "cy": 963.420942916491
      },
      {
          "id": "mzBteT-y4",
          "type": "tool_function",
          "x": 215.43035112941016,
          "y": 373.7264005286,
          "settings": {},
          "height": 124,
          "width": 250,
          "cx": 340.43035112941016,
          "cy": 435.7264005286
      },
      {
          "id": "X3VCdZvY8",
          "type": "prompt",
          "x": -395,
          "y": -649.0218341389162,
          "settings": {
              "role": "system",
              "text": "TODO: Instruct the LLM to leverage the knowledge base if needed to answer the user's questions."
          },
          "height": 316,
          "width": 250,
          "cx": -270,
          "cy": -491.02183413891623
      },
      {
          "id": "PajIbIo5J",
          "type": "extract_value",
          "x": 1337.6488245704968,
          "y": 789.0930187185857,
          "settings": {},
          "height": 102,
          "width": 250,
          "cx": 1462.6488245704968,
          "cy": 840.0930187185857
      },
      {
          "id": "2MxQSI-0D",
          "type": "text",
          "x": 666.9410416553303,
          "y": 717.9177095960526,
          "settings": {
              "text": "search_text"
          },
          "height": 280,
          "width": 250,
          "cx": 791.9410416553303,
          "cy": 857.9177095960526
      },
      {
          "id": "J1hxLpRJ0",
          "type": "semantic_search",
          "x": 1675.2276421813026,
          "y": 789.0930187185857,
          "settings": {
              
          },
          "height": 124,
          "width": 250,
          "cx": 1800.2276421813026,
          "cy": 851.0930187185857
      },
      {
          "id": "2brKSzlbI",
          "type": "messages",
          "x": 2368.7555951593176,
          "y": 619.2120938537685,
          "settings": {},
          "height": 234,
          "width": 250,
          "cx": 2493.7555951593176,
          "cy": 736.2120938537685
      },
      {
          "id": "QVmU8v4ev",
          "type": "gate",
          "x": 2803.8179564760485,
          "y": 473.51082562466456,
          "settings": {},
          "height": 102,
          "width": 250,
          "cx": 2928.8179564760485,
          "cy": 524.5108256246646
      },
      {
          "id": "xgl-0BMqD",
          "type": "gate",
          "x": 1877.4383408608783,
          "y": -420.5969299189697,
          "settings": {},
          "height": 102,
          "width": 250,
          "cx": 2002.4383408608783,
          "cy": -369.5969299189697
      },
      {
          "id": "KwG7Zp_Ao",
          "type": "boolean_inverter",
          "x": 1437.5547594990132,
          "y": -457.8195906149142,
          "settings": {},
          "height": 80,
          "width": 250,
          "cx": 1562.5547594990132,
          "cy": -417.8195906149142
      },
      {
          "id": "uGPzpxQme",
          "type": "gate",
          "x": 1017.850108404669,
          "y": 895.6412824475678,
          "settings": {},
          "height": 102,
          "width": 250,
          "cx": 1142.8501084046688,
          "cy": 946.6412824475678
      },
      {
          "id": "oZR3rFvlw",
          "type": "key_value_maker",
          "x": 1877.4383408608783,
          "y": -293.529138490924,
          "settings": {
              "key": "did_tool_get_used"
          },
          "height": 116,
          "width": 250,
          "cx": 2002.4383408608783,
          "cy": -235.52913849092403
      },
      {
          "id": "tdBeiI42P",
          "type": "openai_gpt_4o_mini",
          "x": 730.0672387693509,
          "y": -403.9271064677358,
          "settings": {},
          "show_additional": true,
          "height": 112,
          "width": 250,
          "cx": 855.0672387693509,
          "cy": -347.9271064677358
      },
      {
          "id": "luU-rxf81",
          "type": "was_function_called",
          "x": 720.5653949093715,
          "y": 373.7264005286,
          "settings": {},
          "height": 124,
          "width": 250,
          "cx": 845.5653949093715,
          "cy": 435.7264005286
      },
      {
          "id": "DDy8sIN53",
          "type": "function_response_formatter",
          "x": 1996.3270031754337,
          "y": 685.2120938537685,
          "settings": {},
          "height": 102,
          "width": 250,
          "cx": 2121.3270031754337,
          "cy": 736.2120938537685
      }
  ]
  },
  {
    name: "Plugins",
    display_name: "Web & Knowledge Plugins",
    description: "Use plugins to extend the functionality of your flow.",
    flow_links: [
      {
          "from": {
              "node_id": "qq8fZCGKz",
              "output": "request_messages"
          },
          "to": {
              "node_id": "HBDeZXmiZ",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "RHye34G5i",
              "output": "plugin_config"
          },
          "to": {
              "node_id": "HBDeZXmiZ",
              "input": "plugins"
          }
      },
      {
          "from": {
              "node_id": "HBDeZXmiZ",
              "output": "message"
          },
          "to": {
              "node_id": "qsp0lxSGM",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "e5xWSteDm",
              "output": "plugin_config"
          },
          "to": {
              "node_id": "HBDeZXmiZ",
              "input": "plugins"
          }
      }
  ],
  flow_nodes: [
    {
        "id": "qq8fZCGKz",
        "type": "request",
        "x": -454.23737578339626,
        "y": -104.82416693718878,
        "settings": {}
    },
    {
        "id": "HBDeZXmiZ",
        "type": "openai_gpt_4o_mini",
        "x": -125.29781534383584,
        "y": -104.82416693718878,
        "settings": {}
    },
    {
        "id": "RHye34G5i",
        "type": "google_search",
        "x": -125.29781534383584,
        "y": 37.17583306281122,
        "settings": {}
    },
    {
        "id": "qsp0lxSGM",
        "type": "response",
        "x": 179.43337442558584,
        "y": -104.82416693718878,
        "settings": {}
    },
    {
        "id": "e5xWSteDm",
        "type": "semantic_search",
        "x": -125.29781534383584,
        "y": 169.17583306281122,
        "settings": {
            
        }
    }
  ]
  }
]

export default flow_templates;